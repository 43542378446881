import Wishlist from 'pages/Wishlist';
import Shows from 'pages/Shows';
import LiveShow from 'pages/LiveShow';
import Cangrejo from 'pages/Cangrejo';
import CangrejoConductor from 'pages/CangrejoConductor';

const routes = [
  { path: '/', redirect: '/shows' },
  { path: '/shows', private: true, action: () => Shows },
  { path: '/wishlist', private: true, action: () => Wishlist },
  { path: '/liveshow', private: true, action: () => LiveShow },
  { path: '/cangrejo', private: true, action: () => Cangrejo },
  { path: '/cangrejo-presentador', private: true, action: () => CangrejoConductor },
];

export default routes;

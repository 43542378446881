import React, { useState } from 'react';
import { Button, Container, Header, Icon, Input, Segment } from 'semantic-ui-react'
import styled from 'styled-components';
import { useBeiContext } from 'beiContext';
import { PersistentInput } from './PersistentInput';
import { usePersistentState } from './usePersistentState';
import { messageTypes } from '../services/liveshowApi';

const HeaderContainer = styled(Header)`
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  margin-bottom: 0.5em;
`;

const FinalText = styled(Input)`
  margin-top: 1em;
`;

function TextoEnLaPlaya() {
  const { streamer, hooks: { useLiveshowApi } } = useBeiContext();
  const [remoteInsertedText, setRemoteInsertedText] = useLiveshowApi(messageTypes.InsertedText, '');
  const [playaText, setPlayaText] = useState('');
  const [inputs, setInputs] = usePersistentState('TextoEnLaPlaya.inputs', 0);

  const setInsertText = (text) => {
    setPlayaText(text);
    setRemoteInsertedText(text);
    streamer.insertText(text);
  };
  React.useEffect(() => {
    const text = remoteInsertedText || '';
    setPlayaText(text);
    streamer.insertText(text);
  }, [remoteInsertedText, streamer]);

  return (
    <Container>
      <Segment>
        <HeaderContainer dividing>
          Texto en la playa
          <Button.Group icon>
            <Button onClick={() => setInputs(current => current + 1)}>
              <Icon name="plus" />
            </Button>
            <Button onClick={() => setInputs(current => Math.max(0, current - 1))}>
              <Icon name="minus" />
            </Button>
          </Button.Group>
        </HeaderContainer>
        {Array.from(new Array(inputs)).map((_, i) => (
          <InputContainer>
            <PersistentInput key={i} persistenceKey={`TextoEnLaPlaya.input_${i}`} onSubmit={setInsertText} />
          </InputContainer>
        ))}
        <FinalText
          fluid
          label={<Button content="Limpiar" onClick={() => setInsertText('')}/>}
          labelPosition='right'
          placeholder='Cualquier texto aquí va directo al streaming...'
          value={playaText}
          onChange={({ target: { value } }) => setInsertText(value)}
        />
      </Segment>
    </Container>
  );
}

export { TextoEnLaPlaya };

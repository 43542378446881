import * as M from '@most/core';
import S, { $ } from 'utils/S';
import uuidv4 from 'uuid/v4';
import { Storage } from 'aws-amplify';
import { fromObservable } from 'utils/getStreamSource';
import showTemplateQueries, { ShowTemplates } from './queries';

const sortItems = ({ date: idA }, { date: idB }) => idB < idA;

export function getShowTemplatesBackend(client) {
  let watchVariables = {};

  return {
    get(showTemplateId) {
      const { __typename, ...showTemplate } = client.readFragment({
        id: `ShowTemplate:${showTemplateId}`,
        fragment: ShowTemplates.fragments.showTemplateData,
      });
      return showTemplate;
    },
    create: ({ icon, ...showTemplate }) =>
      Storage.put(`showIcons/${uuidv4()}.svg`, icon, {
        contentType: 'image/svg+xml',
      }).then(({ key: iconUrl }) =>
        client.mutate({
          mutation: showTemplateQueries.create,
          variables: { input: { icon: iconUrl, ...showTemplate } },
          update: (proxy, response) =>
            S.map(resolvedData => {
              const query = showTemplateQueries.list;
              const variables = watchVariables;
              const data = proxy.readQuery({ query, variables });
              if (
                !data.listShowTemplates.items.find(
                  showTemplate => showTemplate.id === resolvedData.id,
                )
              ) {
                data.listShowTemplates.items.push(resolvedData);
                proxy.writeQuery({ query, variables, data });
              }
              return null;
            })(
              S.gets(S.is($.Object))(['data', 'createShowTemplate'])(response),
            ),
        }),
      ),
    remove: ({ id }) =>
      client.mutate({
        mutation: showTemplateQueries.remove,
        variables: { input: { id } },
        update: (proxy, response) =>
          S.map(({ id }) => {
            const query = showTemplateQueries.list;
            const variables = watchVariables;
            const data = proxy.readQuery({ query, variables });
            data.listShowTemplates.items = data.listShowTemplates.items.filter(
              item => item.id !== id,
            );
            return proxy.writeQuery({ query, variables, data });
          })(S.gets(S.is($.Object))(['data', 'removeShowTemplate'])(response)),
      }),
    update: ({ icon, ...showTemplate }) =>
      (icon
        ? Storage.put(`showIcons/${uuidv4()}.svg`, icon, {
            contentType: 'image/svg+xml',
          })
        : Promise.resolve({})
      ).then(({ key: iconUrl }) =>
        client.mutate({
          mutation: showTemplateQueries.update,
          variables: {
            input: { ...(iconUrl ? { icon: iconUrl } : {}), ...showTemplate },
          },
          update: (proxy, response) =>
            S.map(updateShow => {
              const query = showTemplateQueries.list;
              const variables = watchVariables;
              const data = proxy.readQuery({ query, variables });
              const itemIndex = data.listSessions.items.findIndex(
                ({ id }) => id === showTemplate.id,
              );
              data.listSessions.items[itemIndex] = {
                ...data.listSessions.items[itemIndex],
                ...updateShow,
              };
              return proxy.writeQuery({ query, variables, data });
            })(S.gets(S.is($.Object))(['data', 'updateShow'])(response)),
        }),
      ),
    watch(after) {
      watchVariables = { after };
      const query = client.watchQuery({
        query: showTemplateQueries.list,
        variables: watchVariables,
        notifyOnNetworkStatusChange: true,
      });
      return S.pipe([
        fromObservable,
        M.map(result => ({
          data: S.fromMaybe([])(
            S.gets(S.is($.Array($.Object)))([
              'data',
              'listShowTemplates',
              'items',
            ])(result),
          ),
          loading: result.loading,
          fetchMore: after =>
            query.fetchMore({
              variables: { ...watchVariables, after },
              updateQuery: (prev, { fetchMoreResult }) => {
                return {
                  ...prev,
                  listShowTemplates: {
                    ...prev.listShowTemplates,
                    items: [
                      ...prev.listShowTemplates.items,
                      ...fetchMoreResult.listShowTemplates.items,
                    ]
                      .sort(sortItems)
                      .filter((item, i, a) => item.id !== (a[i + 1] || {}).id),
                  },
                };
              },
            }),
        })),
        M.map(({ data, ...rest }) => ({
          data: data.sort(sortItems).map(itemBackend => {
            const { __typename: __tp1, ...item } = itemBackend;
            const {
              location: { __typename: __tp2, ...location },
            } = item;
            return {
              ...item,
              location,
            };
          }),
          ...rest,
        })),
        M.recoverWith(error =>
          M.now({
            data: [],
            loading: false,
            fetchMore: () => {},
            error: new Error(
              {
                'Network error: Response not successful: Received status code 403':
                  'No autorizado',
              }[error.message] || error.message,
            ),
          }),
        ),
      ])(query);
    },
  };
}

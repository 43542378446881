import S from 'utils/S';
import { startWith, skipRepeats } from '@most/core';
import getStreamSource from 'utils/getStreamSource';

const defaultValues = {
};

const allKeysExist = (keys, obj) => keys.reduce((result, key) => result && Boolean(obj[key]), true);
// const anyKeyExist = (keys, obj) => keys.reduce((result, key) => result || Boolean(obj[key]), false);

export const getShowsFactory = () => (showData = {}) => {
  const initialData = Object.freeze(Object.assign(Object.create(null), defaultValues, showData));
  let data = Object.assign(Object.create(null), initialData);

  const { stream: saveState, push: saveStatePush } = getStreamSource();

  const getSaveState = showData => ({
    canSave: allKeysExist(['date', 'title', 'icon', 'location'], showData),
  });

  const getUpdatedData = () => [...Object.keys(initialData), ...Object.keys(data)].reduce((result, key) => ({
    ...result,
    ...(initialData[key] !== data[key] ? { [key]: data[key] } : {}),
  }), { id: initialData.id });

  return {
    data,
    edit(id, value) {
      // Update store
      data[id] = value;
      // Update streams
      saveStatePush(getSaveState(data));
    },
    saveState: skipRepeats(startWith(getSaveState(initialData), saveState)),
    hasChanged: () => !S.equals(initialData)(data),
    get updatedData() { return getUpdatedData(); },
  };
};

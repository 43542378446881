import Amplify, { Storage } from 'aws-amplify';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports);
Storage.configure({
  AWSS3: {
    bucket: process.env.REACT_APP_MEDIA_BUCKET,
    region: process.env.REACT_APP_REGION,
  }
});

export { login, logout, isLoggedIn, getCurrentCredentials } from './auth';
export { appsyncInit } from './appsync';

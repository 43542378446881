import React from 'react';
import styled from 'styled-components';
import { useBeiContext } from 'beiContext';
import { messageTypes } from '../services/liveshowApi';

const StreamPreviewBase = styled.img`
  width: 320px;
  height: 180px;
  border: 4px solid #575671;
  box-sizing: content-box;
  border-radius: 4px;
`;

export default function StreamPreview(props) {
  const { hooks: { useLiveshowApi } } = useBeiContext();
  const [streamPreview] = useLiveshowApi(messageTypes.Screenshot);

  return streamPreview && (
    <StreamPreviewBase src={streamPreview} alt='Stream preview' />
  );
}

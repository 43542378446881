import * as aws from 'aws';
import S from 'utils/S';

import { multicast, startWith } from '@most/core';
import getStreamSource from 'utils/getStreamSource';

function getController(initHandler = () => {}, initState = null) {
  const { stream: state, push: updateState, end: finish } = getStreamSource();

  Promise.resolve(initHandler()).then(state => {
    if (typeof state !== 'undefined') {
      updateState(state);
    }
  });

  return {
    action(name, handler) {
      this[name] = (...args) => Promise.resolve(handler.call({ updateState }, ...args));
      return this;
    },
    state: multicast(startWith(initState, state)),
    finish,
  };
}

export const auth = getController(() => aws.isLoggedIn().then(loggedIn => loggedIn ? S.Right('loggedIn') : S.Right('loggedOut')), S.Right('init'))
  .action('login', function loginHandler(password) {
    this.updateState(S.Right('loggingIn'));
    aws.login({ email: process.env.REACT_APP_LOGIN_USER, password })
      .then(() => this.updateState(S.Right('loggedIn')))
      .catch(error => this.updateState(S.Left(error.message)));
  })
  .action('logout', function logoutHandler() {
    this.updateState(S.Right('loggingOut'));
    aws.logout()
      .then(() => this.updateState(S.Right('loggedOut')));
  });

import React from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Icon,
  Popup,
} from 'semantic-ui-react'
import styled from 'styled-components';
import { useBeiContext } from 'beiContext';
import { messageTypes } from '../services/liveshowApi';
import { Player } from './Player';

const GlobalControls = styled(Grid.Column)`
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  & > :nth-child(1) {
    margin: -4px 0 14px 0;
  }
  & > :nth-child(5) {
    margin-bottom: 6px;
  }
  & > :nth-child(6) {
    margin-bottom: 10px;
    align-self: flex-end;
  }
  & > :nth-child(7) {
    margin-bottom: 3px;
    align-self: flex-end;
  }
  & > :nth-child(8) {
    margin-bottom: 6px;
  }
`;

const VoteControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const voteMessages = [
  messageTypes.Player1Vote,
  messageTypes.Player2Vote,
  messageTypes.Player3Vote,
  messageTypes.Player4Vote,
];

function Players(props) {
  const {
    liveshowApi,
    hooks: { useLiveshowApi },
  } = useBeiContext();

  const [overlays, setOverlays, loadingOverlays] = useLiveshowApi(messageTypes.Overlays, { percebes: false, playerTexts: false });
  const [backendVotes] = useLiveshowApi(messageTypes.Votes, []);
  const [{ voting }, setAudienceVoting, loadingAudienceVoting] = useLiveshowApi(messageTypes.AudienceVoting, { voting: false });

  const playerVotes = backendVotes.reduce((result, vote) => {
    const voteIndex = voteMessages.indexOf(vote);
    if (voteIndex > -1) {
      result[voteIndex] += 1;
    }
    return result;
  }, [0, 0, 0, 0]);
  const playerRefs = [React.useRef(), React.useRef(), React.useRef(), React.useRef()];
  const textsVisible = overlays.playerTexts;
  const percebesVisible = overlays.percebes;

  return (
    <Grid celled>
      <Grid.Row>
        <GlobalControls width={3}>
            <Button color='teal' basic onClick={() => playerRefs.forEach(({ current }) => { if (current) current.setPlayerName(); })}>
              Nombres <Icon name='angle down' />
            </Button>
            <Button color='teal' basic onClick={() => playerRefs.forEach(({ current }) => { if (current) current.setPlayerText(0); })}>
              Puntos <Icon name='angle down' />
            </Button>
            <Button color='teal' basic icon onClick={() => playerRefs.forEach(({ current }) => { if (current) current.setPlayerText(1); })}>
              <Icon name='angle down' />
            </Button>
            <Button color='teal' basic icon onClick={() => playerRefs.forEach(({ current }) => { if (current) current.setPlayerText(2); })}>
              <Icon name='angle down' />
            </Button>
            <Button color='teal' basic icon onClick={() => playerRefs.forEach(({ current }) => { if (current) current.setPlayerText(3); })}>
              <Icon name='angle down' />
            </Button>
            <Checkbox
              label={textsVisible ? 'Ocultar textos de jugadores' : 'Mostrar textos de jugadores'}
              toggle
              checked={textsVisible}
              onChange={(_, { checked }) => setOverlays({ ...overlays, playerTexts: checked })}
              disabled={loadingOverlays}
            />
            <Checkbox
              label={percebesVisible ? 'Ocultar percebes' : 'Mostrar percebes'}
              toggle
              checked={percebesVisible}
              onChange={(_, { checked }) => setOverlays({ ...overlays, percebes: checked })}
              disabled={loadingOverlays}
            />
            <VoteControls>
              <Popup content='Restaurar las votaciones a cero' trigger={(
                <Button basic color='red' size='mini' onClick={() => liveshowApi.sendMessage(messageTypes.ClearSuggestions, messageTypes.Votes)}>
                  Limpiar <Icon name='remove' />
                </Button>
              )} />
              <Checkbox
                label={voting ? 'Cerrar votaciones' : 'Abrir votaciones'}
                toggle
                checked={voting}
                onChange={(_, { checked }) => setAudienceVoting(checked)}
                disabled={loadingAudienceVoting}
              />
            </VoteControls>
        </GlobalControls>
        {props.players.map((player, i) => (
          <Player
            key={i}
            ref={playerRefs[i]}
            id={`player${i + 1}`}
            player={player}
            votes={playerVotes[i]}
            onColorUpdate={color => props.onUpdate(i, { ...player, color })}
            onNameUpdate={name => props.onUpdate(i, { ...player, name })}
            onScoreUpdate={score => props.onUpdate(i, { ...player, score })}
            onTextUpdate={text => props.onUpdate(i, { ...player, text })}
            onPercebesUpdate={percebes => props.onUpdate(i, { ...player, percebes })}
          />
        ))}
      </Grid.Row>
    </Grid>
  );
}

export default Players;

import YoutubePlayer from 'react-youtube';
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Input,
  Grid,
  Icon,
  Header,
  List,
  Segment,
  Button,
  Popup,
} from 'semantic-ui-react';
import { useBeiContext } from 'beiContext';
import AdminLayout from 'components/AdminLayout';
import { Streamer } from './Streamer';
import './liveshow.css';
import { messageTypes } from '../services/liveshowApi';

const getYoutubePreview = (videoYoutube) => {
  const match = videoYoutube.match(/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/);
  return match ? (
    <YoutubePlayer videoId={match[5]} opts={{ height: '185', width: '320' }} />
  ) : null;
};

const ReceivedSuggestions = ({ suggestions, suggestionType, children }) => {
  const [visible, setVisible] = useState(false);
  const { liveshowApi: { sendMessage } } = useBeiContext();

  return visible
    ? (
      <div>
        <Button basic icon onClick={() => setVisible(false)}>
          <Icon name='eye slash' />
        </Button>
        <Popup content='Eliminar estas sugerencias' trigger={(
          <Button basic icon color="red" onClick={() => sendMessage(messageTypes.ClearSuggestions, suggestionType)}>
            <Icon name='remove' />
          </Button>
        )} />
        {children({ suggestions, suggestionType })}
      </div>
    ) : (
      <Button basic icon onClick={() => setVisible(true)} positive={suggestions && suggestions.length > 0}>
        <Icon name='eye' />
      </Button>
    )
}

const WordCloud = ({ words, suggestionType, onWordSelect }) => {
  return (
    <ReceivedSuggestions suggestions={words} suggestionType={suggestionType}>
      {({ suggestions, suggestionType }) => {
        return (suggestions && suggestions.length > 0 && (
          <Segment>
          <List className="suggestions" relaxed>
            {suggestions.map((suggestion, i) => (
              <List.Item key={i}>
                <List.Content>
                  <List.Header>{suggestion}</List.Header>
                </List.Content>
              </List.Item>
            ))}
          </List>
          </Segment>
        )) || '¡Paciencia! Aún no han llegado sugerencias...';
      }}
    </ReceivedSuggestions>
  );
  /*
  const wordCloudSize = useMemo(() => [600, 300], []);
  return (
    <ReceivedSuggestions suggestions={words} suggestionType={suggestionType}>
      {({ suggestions, suggestionType }) => {
        return (suggestions && suggestions.length > 0 && (
          <ReactWordcloud
            callbacks={{
              getWordTooltip: ({ text }) => `Seleccionar "${text}" provisionalmente`,
              onWordClick: ({ text }) => onWordSelect(suggestionType, text),
            }}
            words={words.map((text) => ({ text, value: 1 }))}
            size={wordCloudSize}
          />
        )) || '¡Paciencia! Aún no han llegado sugerencias...';
      }}
    </ReceivedSuggestions>
  );
   */
}

const SuggestionRow = ({ suggestionType, name, onChecked, suggestionRequests, loadingSuggestionRequests, receivedSuggestions, votes, onSuggestionSelect }) => (
  <Grid.Row>
    <Grid.Column width={3}>
      <Header>{name}</Header>
    </Grid.Column>
    <Grid.Column width={2}>
      <Checkbox
        toggle
        checked={suggestionRequests.includes({ noCorona: 'corona', temaOlimpiadas: 'olimpiadas' }[suggestionType] || suggestionType)}
        onChange={(_, { checked }) => onChecked(checked)}
        disabled={loadingSuggestionRequests}
      />
    </Grid.Column>
    <Grid.Column width={7}>
      {{
        pitonisa: (
          <>
            <Grid.Row style={{ marginBottom: '1em' }}>
              <Grid.Column width={1}>
                Amor
              </Grid.Column>
              <WordCloud words={receivedSuggestions.amor} suggestionType="amor" onWordSelect={onSuggestionSelect} />
            </Grid.Row>
            <Grid.Row style={{ marginBottom: '1em' }}>
              <Grid.Column width={1}>
                Salud
              </Grid.Column>
              <WordCloud words={receivedSuggestions.salud} suggestionType="salud" onWordSelect={onSuggestionSelect} />
            </Grid.Row>
            <Grid.Row style={{ marginBottom: '1em' }}>
              <Grid.Column width={1}>
                Dinero
              </Grid.Column>
              <WordCloud words={receivedSuggestions.dinero} suggestionType="dinero" onWordSelect={onSuggestionSelect} />
            </Grid.Row>
          </>
        ),
        tren: (
          <>
            <Grid.Row style={{ marginBottom: '1em' }}>
              <Grid.Column width={1}>
                Orígen
              </Grid.Column>
              <WordCloud words={receivedSuggestions.trenOrigen} suggestionType="trenOrigen" onWordSelect={onSuggestionSelect} />
            </Grid.Row>
            <Grid.Row style={{ marginBottom: '1em' }}>
              <Grid.Column width={1}>
                Destino
              </Grid.Column>
              <WordCloud words={receivedSuggestions.trenDestino} suggestionType="trenDestino" onWordSelect={onSuggestionSelect} />
            </Grid.Row>
          </>
        ),
        juicio: (
          <>
            <Grid.Row style={{ marginBottom: '1em' }}>
              <Grid.Column width={1}>
                Acusación
              </Grid.Column>
              <WordCloud words={receivedSuggestions.acusacion} suggestionType="acusacion" onWordSelect={onSuggestionSelect} />
            </Grid.Row>
          </>
        ),
        tv: (
          <>
            <Grid.Row style={{ marginBottom: '1em' }}>
              <Grid.Column width={1}>
                Programa de TV que deberíamos imitar
              </Grid.Column>
              <WordCloud words={receivedSuggestions.programaTv} suggestionType="programaTv" onWordSelect={onSuggestionSelect} />
            </Grid.Row>
          </>
        ),
        vote: (
          <>
            <Grid.Row style={{ marginBottom: '1em' }}>
              <ReceivedSuggestions suggestions={votes} suggestionType={messageTypes.Votes}>
                {({ suggestions }) => {
                  const playerVotes = suggestions.reduce((result, suggestion) => ({
                    ...result,
                    [suggestion]: (result[suggestion] || 0) + 1,
                  }), {});
                  return (
                    <div style={{ margin: '1em', display: 'flex' }}>
                      <div style={{
                        backgroundColor: '#c892ff',
                        borderRadius: '100px',
                        textAlign: 'center',
                        padding: '0.25em',
                        flex: 1,
                      }}>
                        <h3>
                          {playerVotes[messageTypes.Player1Vote] || 0}
                        </h3>
                      </div>
                      <div style={{
                        backgroundColor: '#ff6b99',
                        borderRadius: '100px',
                        textAlign: 'center',
                        padding: '0.25em',
                        flex: 1,
                      }}>
                        <h3>
                          {playerVotes[messageTypes.Player2Vote] || 0}
                        </h3>
                      </div>
                      <div style={{
                        backgroundColor: '#00eaf5',
                        borderRadius: '100px',
                        textAlign: 'center',
                        padding: '0.25em',
                        flex: 1,
                      }}>
                        <h3>
                          {playerVotes[messageTypes.Player3Vote] || 0}
                        </h3>
                      </div>
                      <div style={{
                        backgroundColor: '#faff5e',
                        borderRadius: '100px',
                        textAlign: 'center',
                        padding: '0.25em',
                        flex: 1,
                      }}>
                        <h3>
                          {playerVotes[messageTypes.Player4Vote] || 0}
                        </h3>
                      </div>
                      {/*
                      <Button basic icon onClick={() => onSuggestionSelect([
                        playerVotes[messageTypes.Player1Vote] || 0,
                        playerVotes[messageTypes.Player2Vote] || 0,
                        playerVotes[messageTypes.Player3Vote] || 0,
                        playerVotes[messageTypes.Player4Vote] || 0,
                      ])}>
                        Añadir a contadores
                      </Button>
                      */}
                    </div>
                  );
                }}
              </ReceivedSuggestions>
            </Grid.Row>
          </>
        ),
      }[suggestionType] || (
        <WordCloud words={receivedSuggestions[suggestionType]} suggestionType={suggestionType} onWordSelect={onSuggestionSelect} />
      )}
    </Grid.Column>
  </Grid.Row>
);


function Suggestions() {
  const {
    hooks: { useLiveshowApi },
    liveshowApi,
    notification,
  } = useBeiContext();

  const [suggestionRequests, setSuggestion, loadingSuggestionRequests] = useLiveshowApi(messageTypes.SuggestionRequests, []);
  const [insertedText, insertText] = useLiveshowApi(messageTypes.InsertedText, '');
  const [showToStaff] = useLiveshowApi(messageTypes.ShowToStaff);

  useEffect(() => {
    notification.show(showToStaff);
  }, [notification, showToStaff]);

  const receivedSuggestions = {};
  const [audienceSuggestions] = useLiveshowApi(messageTypes.Suggestions, []);
  audienceSuggestions.forEach((viewerSuggestions) => Object.entries(viewerSuggestions).forEach(([suggestionType, suggestion]) => {
    // TODO This is confusing, don't extend it until refactoring
    if ([
      'noCorona',
      'noticia',
      'debate',
      'amor',
      'salud',
      'dinero',
      'temaOlimpiadas',
      'trenOrigen',
      'trenDestino',
      'acusacion',
      'programaTv',
    ].includes(suggestionType)) {
      const { location, name } = viewerSuggestions;
      const suggestionMessage = `${suggestion}${name ? (location ? ` (${name}, de ${location})` : ` (${name})`) : '' }`;
      receivedSuggestions[suggestionType] = [...receivedSuggestions[suggestionType] || [], suggestionMessage];
    }
  }));

  const [suggestionInputProv, setSuggestionInputProv] = useState('');
  const [suggestionInputFinal, setSuggestionInputFinal] = useState('');

  const [votes] = useLiveshowApi(messageTypes.Votes);

  const [donateVisible, setDonateVisible, loadingDonate] = useLiveshowApi(messageTypes.DonateVisible, false);

  const setInsertText = (text) => {
    setSuggestionInputFinal(text);
    insertText(text);
  };

  useEffect(() => {
    setSuggestionInputFinal(insertedText || '');
  }, [insertedText]);

  function handleSuggestionSelect(suggestionType, suggestion) {
    setSuggestionInputProv({
      noCorona: `En vez de 'corona' diremos '${suggestion}'`,
      noticia: `¡Última hora! ${suggestion}`,
      debate: `Debatimos sobre: ${suggestion}`,
      amor: `Amor: ${suggestion}`,
      salud: `Salud: ${suggestion}`,
      dinero: `Dinero: ${suggestion}`,
      temaOlimpiadas: `Olimpiadas de ${suggestion}`,
      trenOrigen: `Este tren sale de ${suggestion}`,
      trenDestino: `Este tren llega a ${suggestion}`,
      acusacion: `Se le acusa de ${suggestion}`,
      programaTv: `Programa de TV: ${suggestion}`,
    }[suggestionType]);
  }

  const handleChecked = (suggestionType) => (show) => setSuggestion({ suggestionType, show });

  const [nameAndLocation, setNameAndLocation] = useState();
  const getNameAndLocation = () => {
    const randomSuggestions = audienceSuggestions
      .sort(() => Math.random() * 2 - 1);

    const picked = randomSuggestions.reduce((current, { name, location }) => ({
      name: current.name ? current.name : name,
      location: current.location ? current.location : location,
    }), { name: '', location: '' });

    const nameAndLocation = picked.name && picked.location
      ? `${picked.name}, de ${picked.location}`
      : '¡Oops, no se encontró a nadie';

    setNameAndLocation(nameAndLocation);
    return nameAndLocation;
  }

  const [videoPopup, setVideoPopup] = useLiveshowApi(messageTypes.VideoPopup, { shown: false, header: '', content: '' });

  const [scores, setScores] = useState([0, 0, 0, 0]);

  const handleVotes = votes => {
    const orderedVotes = [...votes].sort();
    const newScores = scores.map((score, i) => score + ([100, 250, 500, 1000][orderedVotes.indexOf(votes[i])] || 0));
    setScores(newScores);
  }

  /*
      <Grid.Row>
        <Grid.Column width={3}>
          <Header>Pedir donaciones</Header>
        </Grid.Column>
        <Grid.Column width={2}>
          <Checkbox
            toggle
            checked={donateVisible}
            onChange={(_, { checked }) => setDonateVisible(checked)}
            disabled={loadingDonate}
          />
        </Grid.Column>
      </Grid.Row>
  */
  return (
    <Grid celled>
      <Grid.Row>
        <Grid.Column width={4}>
          <Button
            positive
            content={'¡Dame nombre y lugar!'}
            onClick={() => notification.show(getNameAndLocation())}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <h3>{nameAndLocation}</h3>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={3}>
          <Header>Sugerencias</Header>
        </Grid.Column>
        <Grid.Column width={2}>
          <Header as="h4">
            {loadingSuggestionRequests
              ? <Icon loading name='spinner' />
              : 'Pidiendo'
            }
          </Header>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header as="h4">Recibidas</Header>
        </Grid.Column>
      </Grid.Row>
      <SuggestionRow
        suggestionType="noCorona"
        name="Alternativa a 'corona'"
        onChecked={handleChecked('corona')}
        suggestionRequests={suggestionRequests}
        loadingSuggestionRequests={loadingSuggestionRequests}
        receivedSuggestions={receivedSuggestions}
        votes={votes}
        onSuggestionSelect={handleSuggestionSelect}
      />
      <SuggestionRow
        suggestionType="noticia"
        name="Noticia de hoy"
        onChecked={handleChecked('noticia')}
        suggestionRequests={suggestionRequests}
        loadingSuggestionRequests={loadingSuggestionRequests}
        receivedSuggestions={receivedSuggestions}
        votes={votes}
        onSuggestionSelect={handleSuggestionSelect}
      />
      <SuggestionRow
        suggestionType="debate"
        name="Tema del debate"
        onChecked={handleChecked('debate')}
        suggestionRequests={suggestionRequests}
        loadingSuggestionRequests={loadingSuggestionRequests}
        receivedSuggestions={receivedSuggestions}
        votes={votes}
        onSuggestionSelect={handleSuggestionSelect}
      />
      <SuggestionRow
        suggestionType="pitonisa"
        name="Pitonisa"
        onChecked={handleChecked('pitonisa')}
        suggestionRequests={suggestionRequests}
        loadingSuggestionRequests={loadingSuggestionRequests}
        receivedSuggestions={receivedSuggestions}
        votes={votes}
        onSuggestionSelect={handleSuggestionSelect}
      />
      <SuggestionRow
        suggestionType="temaOlimpiadas"
        name="Olimpiadas"
        onChecked={handleChecked('olimpiadas')}
        suggestionRequests={suggestionRequests}
        loadingSuggestionRequests={loadingSuggestionRequests}
        receivedSuggestions={receivedSuggestions}
        votes={votes}
        onSuggestionSelect={handleSuggestionSelect}
      />
      <SuggestionRow
        suggestionType="tren"
        name="Tren"
        onChecked={handleChecked('tren')}
        suggestionRequests={suggestionRequests}
        loadingSuggestionRequests={loadingSuggestionRequests}
        receivedSuggestions={receivedSuggestions}
        votes={votes}
        onSuggestionSelect={handleSuggestionSelect}
      />
      <SuggestionRow
        suggestionType="juicio"
        name="Juicio"
        onChecked={handleChecked('juicio')}
        suggestionRequests={suggestionRequests}
        loadingSuggestionRequests={loadingSuggestionRequests}
        receivedSuggestions={receivedSuggestions}
      />
      <SuggestionRow
        suggestionType="tv"
        name="TV"
        onChecked={handleChecked('tv')}
        suggestionRequests={suggestionRequests}
        loadingSuggestionRequests={loadingSuggestionRequests}
        receivedSuggestions={receivedSuggestions}
      />
      <SuggestionRow
        suggestionType="vote"
        name="Pedir votaciones"
        onChecked={handleChecked('vote')}
        suggestionRequests={suggestionRequests}
        loadingSuggestionRequests={loadingSuggestionRequests}
        receivedSuggestions={receivedSuggestions}
        votes={votes}
        onSuggestionSelect={handleVotes}
      />
      <Grid.Row>
        <Grid.Column width={4}>
          <Header>Título provisional</Header>
        </Grid.Column>
        <Grid.Column width={6}>
          <Input
            fluid
            label={<Button positive content="Usar" onClick={() => setInsertText(suggestionInputProv)}/>}
            labelPosition='right'
            placeholder="Preparar texto aquí..."
            value={suggestionInputProv}
            onChange={({ target: { value } }) => setSuggestionInputProv(value)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <Header>Título insertado</Header>
        </Grid.Column>
        <Grid.Column width={6}>
          <Input
            fluid
            placeholder='Cualquier texto aquí va directo al streaming...'
            value={suggestionInputFinal}
            onChange={({ target: { value } }) => setInsertText(value)}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Button
            content={'Enseñar a improvisadores'}
            onClick={() => liveshowApi.sendMessage(messageTypes.ShowToStaff, suggestionInputFinal)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={3}>
          <Header>Video popup</Header>
        </Grid.Column>
        <Grid.Column width={3}>
          <h3>Header</h3>
          <Input
            fluid
            placeholder='Header...'
            value={videoPopup.header}
            onChange={({ target: { value } }) => setVideoPopup({ ...videoPopup, header: value })}
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <h3>Content</h3>
          <Input
            fluid
            placeholder='Content...'
            value={videoPopup.content}
            onChange={({ target: { value } }) => setVideoPopup({ ...videoPopup, content: value })}
          />
        </Grid.Column>
        <Grid.Column width={2}>
          <Checkbox
            toggle
            checked={videoPopup.shown}
            onChange={(_, { checked }) => setVideoPopup({ ...videoPopup, shown: checked })}
          />
        </Grid.Column>
      </Grid.Row>
      <Streamer insertedText={suggestionInputFinal} />
    </Grid>
  );
}

const LiveShow = () => {
  const [state, setState] = useState({
    streamSource: '',
    videoYoutube: '',
    videoS3: '',
    musicYoutube: '',
    musicSpotify: '',
    musicS3: '',
  });

  const {
    liveshowApi,
    hooks: { useLiveshowApi },
  } = useBeiContext();
  const [streamSource] = useLiveshowApi(messageTypes.StreamSource);

  useEffect(() => {
    if (streamSource) setState((state) => ({ ...state, streamSource }));
  }, [streamSource]);

  const updateField = (fieldType, source) => setState((currentState) => ({ ...currentState, [fieldType]: source }));
  const handleInput = inputType => ({ target: { value } }) => updateField(inputType, value);
  const handleSubmit = inputType => () => liveshowApi.sendMessage(inputType, state[inputType]);

  return (
    <AdminLayout>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Header as="h2">Camerino</Header>
          </Grid.Row>
          <Suggestions />
          <Grid.Row>
            <Header style={{ marginTop: 'auto', marginBottom: 'auto' }} as="h3">Streaming</Header>
            <Grid.Column width={5}>
              <Input
                placeholder={'Origen del streaming...'}
                onChange={handleInput(messageTypes.StreamSource)}
                action={{ color: 'orange', content: 'Establecer streaming', onClick: handleSubmit(messageTypes.StreamSource) }}
                value={state.streamSource}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              {getYoutubePreview(state.streamSource)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </AdminLayout>
  );
};
/*
          <Divider />
          <Grid.Row>
            <Header style={{ marginTop: 'auto', marginBottom: 'auto' }} as="h3">Vídeo</Header>
            <Grid.Column width={5}>
              <Input
                disabled={true}
                placeholder={'Enlace de YouTube...'}
                onChange={handleInput(liveshowApi.mediaTypes.VideoYoutube)}
                action={{ color: 'teal', content: 'Enviar', onClick: handleSubmit(liveshowApi.mediaTypes.VideoYoutube) }}
                value={state.videoYoutube}
              />
              {getYoutubePreview(state.videoYoutube)}
            </Grid.Column>
            <Grid.Column width={10}>
              <Input
                disabled={true}
                placeholder={'Enlace a S3...'}
                onChange={handleInput(liveshowApi.mediaTypes.VideoS3)}
                action={{ color: 'teal', content: 'Enviar', onClick: handleSubmit(liveshowApi.mediaTypes.VideoS3) }}
                value={state.videoS3}
              />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Header as="h3">Música</Header>
            <Grid.Column width={4}>
              <Input
                disabled={true}
                placeholder={'Enlace de YouTube...'}
                onChange={handleInput(liveshowApi.mediaTypes.MusicYoutube)}
                action={{ color: 'teal', content: 'Enviar', onClick: handleSubmit(liveshowApi.mediaTypes.MusicYoutube) }}
                value={state.musicYoutube}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Input
                disabled={true}
                placeholder={'Enlace a S3...'}
                onChange={handleInput(liveshowApi.mediaTypes.MusicS3)}
                action={{ color: 'teal', content: 'Enviar', onClick: handleSubmit(liveshowApi.mediaTypes.MusicS3) }}
                value={state.musicS3}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Input
                disabled={true}
                placeholder={'URI de Spotify...'}
                onChange={handleInput(liveshowApi.mediaTypes.MusicSpotify)}
                action={{ color: 'teal', content: 'Enviar', onClick: handleSubmit(liveshowApi.mediaTypes.MusicSpotify) }}
                value={state.musicSpotify}
              />
            </Grid.Column>
          </Grid.Row>
*/

export default LiveShow;

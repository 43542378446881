import React, { Suspense } from 'react';
import { Segment, Dimmer, Loader} from 'semantic-ui-react';
import AdminLayout from 'components/AdminLayout';
import { ErrorBoundary } from 'components/ErrorBoundary';

const Wishlist = React.lazy(() => import('./Wishlist'));

const WishlistPage = () => {
  return (
    <AdminLayout>
      <Suspense fallback={(
	<Segment style={{ height: '80vh', margin: '8em 20vw' }}>
	  <Dimmer active>
	    <Loader>Cargando corasones</Loader>
	  </Dimmer>
	</Segment>
      )}>
        <ErrorBoundary fallback={error => (
          <div style={{ margin: '5em' }}>
            <h3>
              Ups, hubo algún problema al cargar los corazones...
            </h3>
            <h4 style={{ color: '#CC0000' }}>
              {error.message}
            </h4>
          </div>
        )}>
          <Wishlist />
        </ErrorBoundary>
      </Suspense>
    </AdminLayout>
  );
};

export default WishlistPage;

import React from 'react';
import {
  Container,
  Checkbox,
  Header,
  Segment,
} from 'semantic-ui-react'
import { useBeiContext } from 'beiContext';
import { Intro, Jugo, Pensamientos, Conga, Countdown, Outro } from 'components/Curtain';
import { messageTypes } from '../services/liveshowApi';

function Curtains() {
  const { hooks: { useLiveshowApi } } = useBeiContext();

  const [overlays, setOverlays, loadingOverlays] = useLiveshowApi(messageTypes.Overlays, false);

  const inVisible = overlays.in;
  const outVisible = overlays.out;
  return (
    <Container>
      <Segment>
        <Header dividing>
          Cortinas
        </Header>
        <Checkbox
          label={'Máscara intro'}
          checked={inVisible}
          onChange={(_, { checked }) => setOverlays({ ...overlays, in: checked })}
          disabled={loadingOverlays}
        />
        <Intro.Button />
        <Jugo.Button />
        <Pensamientos.Button />
        <Conga.Button />
        <Countdown.Button />
        <Outro.Button />
        <Checkbox
          label={'Máscara outro'}
          checked={outVisible}
          onChange={(_, { checked }) => setOverlays({ ...overlays, out: checked })}
          disabled={loadingOverlays}
        />
      </Segment>
    </Container>
  );
}

export { Curtains };

import React from 'react';
import styled from 'styled-components';

const StreamSlotContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a0a0a0;
  border: 1px dashed;
  grid-area: ${props => props.type};
`;

const SelectedPlayer = styled.p`
  color: ${props => props.color};
  font-weight: 700;
  font-size: 24px;
`;

const RoleSelectorContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const RoleSelector = styled.div`
  background-color: ${props => props.color};
  height: 2em;
  width: 2em;
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin-right: 4px;
  cursor: pointer;
  font-size: 12px;
`;

function StreamSlot(props) {
  if (props.players) {
    const player = props.players.find(player => player.role === props.type);
    const onRoleChange = playerIndex => props.setPlayerRole(playerIndex, props.type);

    return (
      <StreamSlotContainer type={props.type}>
        {player && (
          <SelectedPlayer color={player.color}>
            {player.name}
          </SelectedPlayer>
        )}
        <RoleSelectorContainer>
          {props.players.map((player, i) => (
              <RoleSelector key={i} color={player.color} onClick={() => onRoleChange(i)}>
                {`${player.name[0]}${player.name[1]}`}
              </RoleSelector>
          ))}
        </RoleSelectorContainer>
      </StreamSlotContainer>
    );
  }
  return (
    <StreamSlotContainer type={props.type}>
      {props.children}
    </StreamSlotContainer>
  );
}

export default StreamSlot;

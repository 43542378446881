import 'moment/locale/es';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { appsyncInit } from 'aws';
import { BeiProvider } from './beiContext';
import { auth } from 'auth';
import { initServices } from 'servicePool';

export const getMain = ({ auth, getClient = appsyncInit }) => ({ children }) => {
  const [services, setServices] = useState();
  useEffect(() => { initServices(getClient).then(setServices); }, []);
  return services
    ? (
      <BeiProvider auth={auth} {...services}>
        {children}
      </BeiProvider>
    ) : null;
};

const Main = getMain({ auth });

export const beiRender = container => Component => Component && ReactDOM.render((
  <Main>
    <Component />
  </Main>
), container);

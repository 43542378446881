import React from 'react';
import { useBeiContext } from 'beiContext';
import styled, { keyframes, css } from 'styled-components';
import { ProgressButtonBase } from './ProgressButton';
import { messageTypes } from '../services/liveshowApi';

const progress = keyframes`
  0%   { transform: scaleX(0); }
  100% { transform: scaleX(1); }
`;

const ProgressBar = styled.div`
  position: relative;
  background-color: #E5E6EF5C;
  height: 4px;
  width: 100%;
  margin: 5px 0;
  border-radius: 2px;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #50bb67;
    transform-origin: 0% 100%;
    border-radius: 4px;
    transform: scaleX(0);
    ${props => props.inProgress && css`
      animation: ${progress} ${props => props.duration}ms linear;
    `};
  }
`;

const CurtainBaseContainer = styled.div`
  padding: 1em;
  background-color: #616267;
  color: #ffffffd0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  margin: 1em;
`;

const Content = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${props => props.highlight ? '#a1ffadd0' : 'inherit'};
  transition: color 0.3s ease-out;
`;
const RemainingTime = styled.div`
  font-family: monospace;
  font-size: 18px;
  font-weight: 600;
`;

const CurtainBase = styled(props => {
  return (
    <CurtainBaseContainer>
      <Content highlight={props.inProgress}>{props.content}</Content>
      {props.inProgress && (
        <>
          <ProgressBar inProgress={props.inProgress} duration={props.duration} />
          <RemainingTime>{((props.remainingTime || 0) / 1000).toFixed(1)}</RemainingTime>
        </>
      )}
    </CurtainBaseContainer>
  );
})`
  position: relative;
  padding: 2em;
  background-color: grey;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 4px;
    background-color: #21ba45;
    width: 100%;
    left: 0;
    border-bottom-right-radius: 4px;
    transform-origin: 0% 100%;
    opacity: 0;
    ${props => props.inProgress && css`
      animation: ${progress} ${props => props.duration}ms linear;
    `};
  }
`;

function Curtain({
  duration,
  durationDisplay,
  curtain,
  content,
  runningCurtain,
  ...restProps
}) {
  const [remainingTime, setRemainingTime] = React.useState(null);
  const [inProgress, setInProgress] = React.useState(false);

  React.useEffect(() => {
    if (!inProgress && runningCurtain === curtain) {
      let t = durationDisplay || duration;
      function tick() {
        if (t < 0) {
          setRemainingTime(0);
          clearInterval(interval);
        } else {
          setRemainingTime(t);
        }
        t -= 100;
      }

      const interval = setInterval(tick, 100);
      tick();

      setInProgress(true);
      setTimeout(() => setInProgress(false), duration);
    }
  }, [runningCurtain, content, curtain, duration, durationDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CurtainBase
      {...restProps}
      content={content}
      duration={duration}
      inProgress={inProgress}
      remainingTime={remainingTime}
      onAnimationEnd={() => setRemainingTime(null)}
      disabled
    />
  )
}

function CurtainButton({
  duration,
  durationDisplay,
  curtain,
  content,
  ...restProps
}) {
  const {
    sequences,
    streamer,
    hooks: { useLiveshowApi },
  } = useBeiContext();
  const [runningCurtain, launchCurtain] = useLiveshowApi(messageTypes.Curtain, '');
  const [inProgress, setInProgress] = React.useState(false);
  const [contentState, setContentState] = React.useState(content);

  const onClick = () => {
    launchCurtain(curtain);
    setTimeout(() => launchCurtain(null), 1000);
  };

  React.useEffect(() => {
    if (!inProgress && runningCurtain === curtain) {
      sequences.curtain({
        streamer: streamer,
        curtain,
        duration,
      });
      setInProgress(true);

      let remainingTime = durationDisplay || duration;
      const interval = setInterval(() => {
        remainingTime -= 100;
        if (remainingTime < 0) {
          setContentState(content);
          clearInterval(interval);
        } else {
          setContentState(`${content} | ${(remainingTime / 1000).toFixed(1)}`);
        }
      }, 100);

      setTimeout(() => setInProgress(false), duration);
    }
  }, [runningCurtain, inProgress, curtain, sequences, streamer, duration]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProgressButtonBase {...restProps} content={contentState} duration={duration} onClick={onClick} inProgress={inProgress} disabled={inProgress} />
  )
}

const curtainConfigs = {
  countdown: {
    content: 'Cronómetro',
    curtain: 'curtain_countdown',
    duration: 72017,
    durationDisplay: 62500,
  },
  jugo: {
    content: 'Jugo de Papaya',
    curtain: 'curtain_jugo',
    duration: 21741,
    durationDisplay: 19500,
  },
  pensamientos: {
    content: 'Pensamientos de Caipirinha',
    curtain: 'curtain_pensamientos',
    duration: 20909,
    durationDisplay: 18500,
  },
  conga: {
    content: 'Conga de Jalisco',
    curtain: 'curtain_conga',
    duration: 23000,
    durationDisplay: 21000,
  },
  intro: {
    content: 'Intro',
    curtain: 'curtain_intro',
    duration: 27203,
    durationDisplay: 24000,
  },
  outro: {
    content: 'Outro',
    curtain: 'curtain_outro',
    duration: 44888,
  },
};

Curtain.Button = CurtainButton;

const Jugo = (props) => <Curtain {...props} {...curtainConfigs.jugo} />;
Jugo.Button = (props) => <Curtain.Button {...props} {...curtainConfigs.jugo} />;

const Pensamientos = (props) => <Curtain {...props} {...curtainConfigs.pensamientos} />;
Pensamientos.Button = (props) => <Curtain.Button {...props} {...curtainConfigs.pensamientos} />;

const Conga = (props) => <Curtain {...props} {...curtainConfigs.conga} />;
Conga.Button = (props) => <Curtain.Button {...props} {...curtainConfigs.conga} />;

const Countdown = (props) => <Curtain {...props} {...curtainConfigs.countdown} />;
Countdown.Button = (props) => <Curtain.Button {...props} {...curtainConfigs.countdown} />;
const Countdown2 = (props) => <Curtain {...props} {...curtainConfigs.countdown} content='Cronómetro 2' curtain='curtain_countdown2' />;
Countdown2.Button = (props) => <Curtain.Button {...props} {...curtainConfigs.countdown} content='Cronómetro 2' curtain='curtain_countdown2' />;

const Intro = (props) => <Curtain {...props} {...curtainConfigs.intro} />;
Intro.Button = (props) => <Curtain.Button {...props} {...curtainConfigs.intro} />;

const Outro = (props) => <Curtain {...props} {...curtainConfigs.outro} />;
Outro.Button = (props) => <Curtain.Button {...props} {...curtainConfigs.outro} />;

export default Curtain;
export {
  Curtain,
  CurtainButton,
  Intro,
  Jugo,
  Pensamientos,
  Conga,
  Countdown,
  Countdown2,
  Outro,
};

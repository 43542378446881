const awsExports = {
  "aws_appsync_authenticationType": "AWS_IAM",
  "aws_project_region": process.env.REACT_APP_REGION,
  "aws_cognito_region": process.env.REACT_APP_REGION,
  "aws_appsync_region": process.env.REACT_APP_REGION,
  "aws_cognito_identity_pool_id": process.env.REACT_APP_IDENTITY_POOL,
  "aws_user_pools_id": process.env.REACT_APP_USER_POOL,
  "aws_user_pools_web_client_id": process.env.REACT_APP_WEB_CLIENT,
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_GRAPHQL_ENDPOINT,
  endpoints: [
    {
      name: 'api',
      endpoint: process.env.REACT_APP_API_URL
    }
  ]
};

export default awsExports;

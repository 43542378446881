import React from 'react';
import { Form } from 'semantic-ui-react';

export default function EditableText(props) {
  const [text, setText] = React.useState(props.children);
  const [editing, setEditing] = React.useState(false);

  const handleInputChange = ({ target: { value } }) => setText(value);
  const handleFormSubmit = () => {
    setEditing(false);
    if (typeof props.onChange === 'function' && text !== props.childen) {
      props.onChange(text);
    }
  };

  React.useEffect(() => {
    setText(props.children);
  }, [props.children]);

  if (editing) {
    return (
      <Form onSubmit={handleFormSubmit}>
        <Form.Input value={text} onChange={handleInputChange} />
      </Form>
    );
  }
  return <span onClick={() => setEditing(true)}>{text}</span>;
}

import { create, env } from 'sanctuary';
import $ from 'sanctuary-def';

const checkTypes = process.env.NODE_ENV === 'development';

const PromiseType = $.NullaryType('Promise')('')([])(x => S.type(x).name === 'Promise');
const S = create({ checkTypes, env: env.concat([PromiseType]) });

const def = $.create({ checkTypes, env: $.env });

export { $, def };
export default S;

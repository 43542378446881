import React, { useState } from 'react';
import { Grid, Button, Header, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { useBeiContext } from 'beiContext';
import { useStream } from 'useStream';

import Show from './Show';

function showsReduce({ result, lastMonth }, show) {
  const showMonth = show.date.getMonth();
  const monthComponent = lastMonth !== showMonth && (
    <h2 key={`month-${show.id}`} className="text-month">
      {t[locale].months[showMonth]}
    </h2>
  );
  const showComponent = <Show key={show.id} show={show} />;
  if (monthComponent) {
    // Add new month
    return {
      result: [
        ...result,
        {
          monthComponent,
          showComponents: [showComponent],
        },
      ],
      lastMonth: showMonth,
    };
  }
  // Add show to last month
  const currentMonth = result[result.length - 1];
  result[result.length - 1] = {
    ...currentMonth,
    showComponents: [...currentMonth.showComponents, showComponent],
  };
  return {
    result,
    lastMonth: showMonth,
  };
}

function showsMap({ monthComponent, showComponents }, i) {
  return (
    <React.Fragment key={i}>
      {monthComponent}
      <Grid stackable columns={3}>
        {showComponents.map((showComponent, i) => (
          <Grid.Column key={i}>{showComponent}</Grid.Column>
        ))}
      </Grid>
    </React.Fragment>
  );
}

const t = {
  es: {
    allShows: 'todos los shows',
    doorOpening: 'Apertura',
    reserve: 'Reserva',
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    days: ['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sá'],
    dayNames: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
  },
  en: {
    allShows: 'back to shows',
    doorOpening: 'Door opening',
    reserve: 'Reserve',
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    days: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
    dayNames: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Sábado',
    ],
  },
  de: {
    allShows: 'zurück',
    doorOpening: 'Tür offnung',
    reserve: 'Reservieren',
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    days: ['so', 'mo', 'di', 'mi', 'do', 'fr', 'sa'],
    dayNames: [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ],
  },
};

const dateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
// TODO Language switch
const locale = 'es';
const ShowList = () => {
  const [fetchDate, setFetchDate] = useState(new Date());
  const {
    showsController: { watch },
  } = useBeiContext();
  const { data: shows, loading, error, fetchMore } = useStream(
    watch(fetchDate),
    { data: [], loading: true },
  );
  return error ? (
    <Header color="red">{error.message}</Header>
  ) : (
    <div style={{ marginTop: '2em' }}>
      <Dimmer.Dimmable dimmed={loading}>
        <Button
          onClick={() => {
            const newDate = new Date(fetchDate - 2678400000);
            fetchMore(newDate);
            setFetchDate(newDate);
          }}>
          Cargar shows pasados
        </Button>
        <Header as="h4">
          Shows programados a partir del{' '}
          {fetchDate.toLocaleDateString(locale, dateOptions)}
        </Header>
        {shows.length > 0 ? (
          shows
            .reduce(showsReduce, { result: [], lastMonth: null })
            .result.map(showsMap)
        ) : (
          <p style={styles.noShowsMessage}>
            Bomba.....¡no hay ningún show a la vista! <Icon name="bomb" />
          </p>
        )}
        <Dimmer style={{ pointerEvents: 'none' }} active={loading}>
          <Loader>Cargando shows...</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  );
};

const styles = {
  noShowsMessage: {
    fontSize: '1.33em',
    color: 'silver',
    marginTop: '1em',
  },
};

export default ShowList;

function getNotification() {
  function init() {
    return;
    // Comprobamos si el navegador soporta las notificaciones
    if (!("Notification" in window)) {
      alert("Este navegador no soporta las notificaciones del sistema");
    }

    // Comprobamos si ya nos habían dado permiso
    else if (Notification.permission === "granted") {
    }

    // Si no, tendremos que pedir permiso al usuario
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
	// Si el usuario acepta, lanzamos la notificación
	if (permission === "granted") {
	}
      });
    }
  }

  function close() {}

  function show(message, timeout = 4000) {
    return;
    const n = new Notification(message);
    setTimeout(n.close.bind(n), timeout);
  }

  function countdown(step) {
    return;
    if (step) {
      show({
        6: '...6',
        5: '...cinco',
        4: 'cuatro',
        3: 'Tres',
        2: '¡DOS!',
        1: '¡¡UNO....',
      }[step] || `Cuenta atrás: ${step}`, 1000);
    } else {
      show('....EN EL AIREEEE!!!');
    }
  }

  const notificationApi = {
    init,
    close,
    show,
    countdown,
  };

  return ['init', 'close', 'show', 'countdown'].reduce((result, fn) => ({
    ...result,
    ...typeof notificationApi[fn] === 'function'
      ? { [fn]: notificationApi[fn].bind(notificationApi) }
      : {},
  }), {});
}

export {
  getNotification,
};

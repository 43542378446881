import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import S from 'utils/S';
import { Button, Grid, Header, Segment, Modal } from 'semantic-ui-react';
import { useBeiContext } from 'beiContext';
import { useStream } from 'useStream';

import AdminLayout from 'components/AdminLayout';
import ShowList from 'shows/ShowList';
import AddShow from 'shows/AddShow';
import ShowForm from 'shows/ShowForm';

const Shows = () => {
  const [pipelineRunning, setPipelineRunning] = useState(false);

  function checkPipeline() {
    API
      .get('api', '/pipeline')
      .then(({ running }) => setPipelineRunning(running))
      .catch(error => console.error('[Error fetching pipeline status]', error));
  }

  function startPipeline() {
    API
      .post('api', '/pipeline')
      .then(() => setPipelineRunning(true))
      .catch(error => console.error('[Error starting pipeline]', error));
  }

  useEffect(() => {
    checkPipeline();
    const interval = setInterval(checkPipeline, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <AdminLayout>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={16}>
              <Button primary loading={pipelineRunning} onClick={() => {
                if (!pipelineRunning) {
                  startPipeline();
                }
              }}>
                El botón de Ale 😘
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </AdminLayout>
  );

  /*
  const {
    showsController: {
      select,
      current,
      save,
      remove,
      constants: { OPERATION },
    },
    showTemplatesController: { watch },
  } = useBeiContext();
  const { data: showTemplates } = useStream(watch(), {
    data: [],
    loading: true,
  });
  const currentShow = useStream(current, S.Pair(OPERATION.NONE)(S.Nothing));
  const operation = S.fst(currentShow);
  const show = S.compose(S.maybeToNullable)(S.snd)(currentShow);
  const fullscreen = window.innerWidth < 768;

  return (
    <AdminLayout>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={16}>
              <Button primary loading={pipelineRunning} onClick={() => {
                if (!pipelineRunning) {
                  startPipeline();
                }
              }}>
                El botón de Ale 😘
              </Button>
              <Header as="h2">Shows programados</Header>
              <AddShow />
              <ShowList />
              <Modal
                open={
                  operation !== OPERATION.NONE && operation !== OPERATION.CREATE
                }
                size={fullscreen ? 'fullscreen' : 'large'}
                style={styles[fullscreen ? 'modalFullscreen' : 'modal']}
                onClose={() => select(null)}>
                {show && (
                  <>
                    <Modal.Header style={styles.header}>
                      <div style={styles.headerContainer}>
                        Editar show
                        {fullscreen && (
                          <Button
                            style={{ marginLeft: 14 }}
                            icon="close"
                            onClick={() => select(null)}
                          />
                        )}
                      </div>
                    </Modal.Header>
                    <Modal.Content>
                      <ShowForm
                        show={show}
                        icons={showTemplates.map(({ icon }) => icon)}
                      />
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        onClick={() => remove(show.data)}
                        negative
                        labelPosition="right"
                        icon="delete"
                        content="Eliminar show"
                      />
                      <ShowForm.SaveButton
                        onSave={save}
                        saveStream={show.saveState}
                      />
                    </Modal.Actions>
                  </>
                )}
              </Modal>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </AdminLayout>
  );
  */
};

const styles = {
  modal: {
    marginTop: '0px !important',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  modalFullscreen: {
    top: 0,
    marginTop: 0,
    minHeight: '100%',
  },
  header: {
    paddingRight: '14px !important',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default Shows;

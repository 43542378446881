function getEventEmitter(initialValue) {
  const listeners = new Set();
  let lastMessage = initialValue;

  function eventEmitter(value) {
    lastMessage = value;
    listeners.forEach(listener => listener(value));
  }

  eventEmitter.listen = function listen(listener, getLastMessage = true) {
    listeners.add(listener);

    if (getLastMessage && typeof lastMessage !== 'undefined') {
      listener(lastMessage);
    }

    return function unlisten() {
      listeners.delete(listener);
    };
  }

  eventEmitter.clear = function clear() {
    listeners.clear();
  }

  eventEmitter.value = () => lastMessage;

  return eventEmitter;
}

export { getEventEmitter };

const easeInOutQuad = x =>
  x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;

const interpolate = (from, to, x) =>
  to * x + from * (1 - x);

const sequences = {};

// TODO Use real time instead of tick factors
function tick() {
  Object.entries(sequences).forEach(([id, sequence]) => {
    if (sequence.i >= sequence.factor - 1) {
      sequences[id].i = 0;
      sequence.callback(sequence.getValue(sequence.step));
      if (sequence.step < sequence.stepsNumber) {
        sequences[id].step += 1;
      } else {
        sequence.callback(sequence.to);
        delete sequences[id];
      }
    } else {
      sequences[id].i += 1;
    }
  });
  window.requestAnimationFrame(tick);
}
window.requestAnimationFrame(tick);

const fps = 60;

function sequence(id, [from, to], [duration, interval = 1000 / fps], callback) {
  const factor = interval * fps / 1000;
  const stepsNumber = duration / interval;

  if (stepsNumber < 1 || interval === 0) {
    callback(to);
    return {
      stop: () => {},
    };
  }

  const stepSize = (to - from) / stepsNumber;
  const getValue = stepNumber => from + stepSize * stepNumber;

  if (sequences[id]) {
    sequences[id].getValue = getValue;
  } else {
    sequences[id] = {
      callback,
      getValue,
      factor,
      step: 0,
      stepsNumber,
      to,
      i: factor, // Start immediately
    };
  }

  return {
    stop: () => {
      delete sequences[id];
    },
  };
}

function sequenceOld([from, to], [duration, interval = 10], target) {
  const stepsNumber = duration / interval;

  if (stepsNumber < 1 || interval === 0) {
    target(to);
    return {
      stop: () => {},
    };
  }

  const stepSize = (to - from) / stepsNumber;
  const getValue = stepNumber => from + stepSize * stepNumber;

  let currentStep = 0;
  function tick() {
    target(getValue(currentStep));
    if (currentStep < stepsNumber) {
      currentStep += 1;
    } else {
      clearInterval(id);
    }
  }

  const id = setInterval(tick, interval);
  tick();

  return {
    stop: () => clearInterval(id),
  };
}

function getSequences() {
  function init() {}

  function close() {}

  function curtain({ streamer, curtain, duration }) {
    sequence(
      curtain,
      [1, 0],
      [duration, duration],
      x => streamer.setItemProperties(curtain, { visible: Boolean(x) })
    );
  }

  return {
    init,
    close,
    sequence,
    curtain,
  };
}

export {
  easeInOutQuad,
  interpolate,
  sequenceOld,
  sequence,
  getSequences,
};

import { S3Client } from '@aws-sdk/client-s3-browser/S3Client';
import { ListObjectsV2Command } from '@aws-sdk/client-s3-browser/commands/ListObjectsV2Command';
import { getCurrentCredentials } from '../aws/auth';

function getMediaStorage() {
  const Bucket = 'bei-test-media';
  let s3;

  function init() {
    s3 = new S3Client({
      region: 'eu-central-1',
      credentials: getCurrentCredentials(),
    });
  }

  function test() {
    const listObjects = new ListObjectsV2Command({
      Bucket,
      Prefix: 'SufreKaraoke',
    });
    s3.send(listObjects).then(({ Contents }) => console.log(Contents));
  }

  return {
    init,
    test,
  };
}

export {
  getMediaStorage,
}

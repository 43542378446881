import React, { useEffect, useState, useImperativeHandle } from 'react';
import {
  Button,
  Icon,
  Input,
  Grid,
} from 'semantic-ui-react';
import { ChromePicker } from 'react-color';
import styled, { keyframes } from 'styled-components';
import { useBeiContext } from 'beiContext';
import EditableText from './EditableText';
import NumberInput from './NumberInput';
import { messageTypes } from '../services/liveshowApi';

const Header = styled.div`
  display: flex;
  & > button {
    margin-left: 1em;
  }
`;

const NameButton = styled(Button)`
  position: relative;
  top: -8px;
  left: 0.5em;
`;

const ColoredInput = styled(Input)`
  & > input {
    background-color: ${props => props.color} !important;
  }
`;

const ColorCircle = styled.div`
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${props => props.color};
  right: 16px;
  top: 16px;
  box-shadow: 1px 1px 3px 0px #00000070;
`;

const ColorPicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: -36px 0 16px 0;
`;

const Percebes = styled(NumberInput)`
  margin-top: 1em;
`;

const none = keyframes`
  from {}
  to {}
`;
const upVoted = keyframes`
  from {
    background-color: lime;
    opacity: 0.9;
  }
  to {
    opacity: 0;
  }
`;
const downVoted = keyframes`
  from {
    background-color: orangered;
    opacity: 0.9;
  }
  to {
    opacity: 0;
  }
`;

const VotesContainer = styled.div`
  display: flex;
  marginTop: 1em;
`;

const Votes = styled(Input)`
  position: relative;

  & :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    border-radius: 4px;
    animation: ${props => ({
      up: upVoted,
      down: downVoted,
    }[props.voted] || none)} 0.5s ease-out;
  };
`;

const scores = [800, 700, 600, 500];

const Player = React.forwardRef((props, ref) => {
  const [playerTextLocal, setPlayerTextLocal] = useState('');
  const [playerProvisional2, setPlayerProvisional2] = useState('');
  const [playerProvisional3, setPlayerProvisional3] = useState('');
  const [playerProvisional4, setPlayerProvisional4] = useState(localStorage.getItem(`${props.id}_playerProvisional4`) || '');
  useEffect(() => { localStorage.setItem(`${props.id}_playerProvisional4`, playerProvisional4); }, [playerProvisional4, props.id]);

  useEffect(() => { setPlayerTextLocal(props.player.text || ''); }, [props.player.text]);

  const setPlayerText = (text) => {
    props.onTextUpdate(text);
    setPlayerTextLocal(text);
  };

  useImperativeHandle(ref, () => ({
    setPlayerName() {
      setPlayerText(props.player.name);
    },
    setPlayerText(index) {
      const text = [props.player.score.toString(), playerProvisional2, playerProvisional3, playerProvisional4][index];
      setPlayerText(text);
    },
  }));

  const [voted, setVoted] = useState(null);
  const votesRef = React.useRef(props.votes);
  React.useEffect(() => {
    if (props.votes > votesRef.current) {
      setVoted('up');
    } else if (props.votes < votesRef.current) {
      setVoted('down');
    }
    votesRef.current = props.votes;
  }, [props.votes]);

  const [newColor, setNewColor] = React.useState(props.player.color);
  const [pickColor, setPickColor] = React.useState(false);

  const { liveshowApi } = useBeiContext();

  return (
    <Grid.Column width={3}>
      <Header>
        <h3>
          <EditableText onChange={props.onNameUpdate}>
            {props.player.name}
          </EditableText>
        </h3>
        <NameButton color='teal' basic icon onClick={() => setPlayerText(props.player.name)}>
          <Icon name='angle down' />
        </NameButton>
      </Header>
      <ColorCircle color={props.player.color} onClick={() => setPickColor(true)} />
      {pickColor && (
        <ColorPicker>
          <ChromePicker onChange={({ hex }) => setNewColor(hex)} color={newColor} />
          <Button.Group style={{ marginTop: 8 }}>
            <Button onClick={() => {
              setNewColor(props.player.color);
              setPickColor(false);
            }}>
              Cancelar
            </Button>
            <Button.Or text='o' />
            <Button positive onClick={() => {
              props.onColorUpdate(newColor);
              setPickColor(false);
            }}>
              Aplicar
            </Button>
          </Button.Group>
        </ColorPicker>
      )}
      <Button.Group size='tiny'>
        <Button onClick={() => props.onScoreUpdate(props.player.score + scores[0])}>1</Button>
        <Button.Or text='o' />
        <Button onClick={() => props.onScoreUpdate(props.player.score + scores[1])}>2</Button>
        <Button.Or text='o' />
        <Button onClick={() => props.onScoreUpdate(props.player.score + scores[2])}>3</Button>
        <Button.Or text='o' />
        <Button onClick={() => props.onScoreUpdate(props.player.score + scores[3])}>4</Button>
      </Button.Group>
      <Input
        fluid
        placeholder="Puntos..."
        value={props.player.score}
        onChange={({ target: { value } }) => (
          value.length === 0
            ? props.onScoreUpdate(0)
            : value.match(/^\d+$/)
              ? props.onScoreUpdate(parseInt(value))
              : props.player.score
        )}
	action={{
	  color: 'teal',
	  icon: 'angle down',
          basic: true,
          onClick: () => setPlayerText(props.player.score.toString()),
	}}
      />
      Provisional 2
      <Input
        fluid
        placeholder="Campo libre..."
        value={playerProvisional2}
        onChange={({ target: { value } }) => setPlayerProvisional2(value)}
	action={{
	  color: 'teal',
	  icon: 'angle down',
          basic: true,
          onClick: () => setPlayerText(playerProvisional2),
	}}
      />
      Provisional 3
      <Input
        fluid
        placeholder="Campo libre..."
        value={playerProvisional3}
        onChange={({ target: { value } }) => setPlayerProvisional3(value)}
	action={{
	  color: 'teal',
	  icon: 'angle down',
          basic: true,
          onClick: () => setPlayerText(playerProvisional3),
	}}
      />
      Provisional 4
      <Input
        fluid
        placeholder="Campo libre..."
        value={playerProvisional4}
        onChange={({ target: { value } }) => setPlayerProvisional4(value)}
	action={{
	  color: 'teal',
	  icon: 'angle down',
          basic: true,
          onClick: () => setPlayerText(playerProvisional4),
	}}
      />
      Final
      <ColoredInput
        fluid
        color={props.player.color}
        placeholder={props.player.name}
        value={playerTextLocal}
        onChange={({ target: { value } }) => setPlayerText(value)}
      />
      <Percebes
        fluid
        label='Percebes'
        value={props.player.percebes}
        onChange={props.onPercebesUpdate}
      />
      <VotesContainer>
        <Votes
          label='Votos'
          value={props.votes}
          voted={voted}
          onAnimationEnd={() => setVoted(null)}
        />
        <Button icon onClick={() => {
          const player = [
            messageTypes.Player1Vote,
            messageTypes.Player2Vote,
            messageTypes.Player3Vote,
            messageTypes.Player4Vote,
          ][props.id.split('player')[1] - 1];
          liveshowApi.sendMessage(player, Date.now().toString());
        }}>
          <Icon name='add' />
        </Button>
      </VotesContainer>
    </Grid.Column>
  );
});

export { Player };

import gql from 'graphql-tag';

export const ShowTemplates = {
  fragments: {
    showTemplateData: gql`
      fragment ShowTemplateData on ShowTemplate {
        id
        title
        icon
        description
        locationName
        location {
          latitude
          longitude
        }
      }
    `,
  },
};


const list = gql`
  query ListShowTemplates {
    listShowTemplates {
      items {
        ...ShowTemplateData
      }
      nextToken
    }
  }
  ${ShowTemplates.fragments.showTemplateData}
`;

const create = gql`
  mutation CreateShowTemplate($input: CreateShowTemplateInput!) {
    createShowTemplate(input: $input) {
      ...ShowTemplateData
    }
  }
  ${ShowTemplates.fragments.showTemplateData}
`;

const update = gql`
  mutation UpdateShowTemplate($input: UpdateShowTemplateInput!) {
    updateShowTemplate(input: $input) {
      ...ShowTemplateData
    }
  }
  ${ShowTemplates.fragments.showTemplateData}
`;

const remove = gql`
  mutation RemoveShowTemplate($input: RemoveShowTemplateInput!) {
    removeShowTemplate(input: $input) {
      id
    }
  }
`;

const queries = {
  list,
  create,
  update,
  remove,
};

export default queries;

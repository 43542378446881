import React from 'react';
import { useBeiContext } from 'beiContext';
import AudienceCounter from 'components/AudienceCounter';
import AdminLayout from 'components/AdminLayout';
import Players from 'components/Players';
import Streaming from 'components/Streaming';
import Layout from './sections/Layout';
import { CangrejoProvider } from '../cangrejo/context';
import { Curtains } from '../cangrejo/Curtains';
import { TextoEnLaPlaya } from '../cangrejo/TextoEnLaPlaya';
import { Streamer } from './Streamer';
import './liveshow.css';
import { messageTypes } from '../services/liveshowApi';

function Cangrejo() {
  const {
    hooks: { useLiveshowApi },
    streamer,
  } = useBeiContext();

  const [remotePlayers, setRemotePlayers] = useLiveshowApi(messageTypes.Players);

  // TODO Fix some mess when changing both layout and roles (specially between games)
  const [players, setPlayers] = React.useState(remotePlayers || []);

  const [overlays] = useLiveshowApi(messageTypes.Overlays, false);

  React.useEffect(() => {
    // Update local players when remotes are received
    if (remotePlayers) {
      setPlayers(remotePlayers);
      playersRef.current = remotePlayers;
      
      // Update streamer
      remotePlayers.forEach(player => {
        const playerIndex = player.role.split('idle')[1]; // Warning! This assumes that we are showing percebes in general layout
        const playerId = `player${playerIndex}`;

        // Update texts
        streamer.setPlayerText(playerId, player.text);

        // Update texts visibility
        const textsVisible = overlays.playerTexts;
        streamer.setItemProperties(`text_${playerId}`, { visible: textsVisible });
        streamer.setItemProperties(`text_background_${playerId}`, { visible: textsVisible });

        // Update percebes visibility
        const percebesVisible = overlays.percebes;
        streamer.setItemProperties(`mask_percebe_background_${playerId}`, { visible: percebesVisible });
        streamer.setItemProperties(`mask_percebe_fill_${playerId}`, { visible: percebesVisible && player.percebes > 0 });

        // Set overlays visibility (in/out)
        streamer.setItemProperties('overlay_in', { visible: overlays.in });
        streamer.setItemProperties('overlay_out', { visible: overlays.out });
      });
    }
  }, [remotePlayers, streamer, overlays]);

  const timerRef = React.useRef(null);
  const playersRef = React.useRef(players);
  const updatePlayers = newPlayers => {
    setPlayers(newPlayers);
    playersRef.current = newPlayers;
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => setRemotePlayers(newPlayers), 100);
  };

  return (
    <AdminLayout>
      <CangrejoProvider players={players} setPlayers={updatePlayers}>
        <AudienceCounter />
        <Layout players={players} />
        <Curtains />
        <Players players={players} onUpdate={(i, player) => {
          const newPlayers = [...playersRef.current];
          newPlayers[i] = player;
          updatePlayers(newPlayers);
        }} />
        <TextoEnLaPlaya />
        <Streamer />
        <Streaming />
      </CangrejoProvider>
    </AdminLayout>
  );
}

export default Cangrejo;

import React from 'react'
import { Message } from 'semantic-ui-react'
import Link from 'navigation/Link'

const NotFound = () => (
  <Message>
    <Message.Header>
      Página no encontrada
    </Message.Header>
    <p>
      Whoops, esta página no existe... ¡pero no pasa ná, en la impro no hay error!
    </p>
    <Link to="/wishlist">
      Acepta mi propuesta
    </Link>
  </Message>
);

export default NotFound;

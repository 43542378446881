import { Auth } from 'aws-amplify';

export const getCurrentCredentials = Auth.currentCredentials.bind(Auth);
const isVerified = user => Auth.userAttributes(user)
  .then(attributes => attributes.some(({ Name, Value }) => Name === 'email_verified' && Value));

export const isLoggedIn = () => Auth.currentAuthenticatedUser()
  .then(isVerified)
  .catch(error => error === 'not authenticated'
    ? false
    : Promise.reject(error));
export const login = ({ email, password }) => Auth.signIn(email, password)
  .then(isVerified);
export const logout = Auth.signOut.bind(Auth);

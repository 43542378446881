import React from 'react';
import styled from 'styled-components';
import { useBeiContext } from 'beiContext';
import StreamPreview from '../../components/StreamPreview';
import StreamLayout from '../../components/StreamLayout';
import ZoomLayout from '../../components/ZoomLayout';
import { messageTypes } from '../../services/liveshowApi';
import { Layouts, getSlots } from '../../services/slots';

const LayoutContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const initialSlots = [
  {
    role: 'conductor',
    connected: true,
  },
  {
    role: 'media',
    connected: true,
  },
  {
    role: 0,
    connected: true,
  },
  {
    role: 1,
    connected: true,
  },
  {
    role: 2,
    connected: true,
  },
  {
    role: 3,
    connected: true,
  },
];

const overlay1Layouts = [Layouts.intro, Layouts.couple];

export default function Layout(props) {
  const {
    hooks: { useLiveshowApi },
    streamer,
  } = useBeiContext();
  const [layout, setRemoteLayout] = useLiveshowApi(messageTypes.Layout, Layouts.intro);
  const [{ voting }, setAudienceVoting] = useLiveshowApi(messageTypes.AudienceVoting, { voting: false });

  const setLayout = (layout) => {
    setRemoteLayout(layout);
    if (voting) setTimeout(() => setAudienceVoting(true), 100); // Update web voting layout
  }

  const [slotsService] = React.useState(getSlots(streamer));
  const [slots, setSlots] = React.useState(initialSlots);
  const [streamerConnected, setStreamerConnected] = React.useState(false);

  React.useEffect(() => streamer.isOpen.listen(setStreamerConnected, true), [streamer]);

  // Update streamer on layout updates
  React.useEffect(() => {
    [1, 2, 3, 4].forEach(id => (
      streamer.setItemProperties(`player${id}_general`, {
        visible: [Layouts.general, Layouts.promoUp, Layouts.promoDown].includes(layout),
      })
    ));
    streamer.setItemProperties('countdown_background', {
      visible: layout === Layouts.countdown,
    });
    streamer.setItemProperties('overlay_1', {
      visible: overlay1Layouts.includes(layout)
    });
    streamer.setItemProperties('overlay_2', {
      visible: !overlay1Layouts.includes(layout)
    });
  }, [layout, streamer]);

  // Update streamer on layout, slots or players updates
  // TODO Refactor into services
  React.useEffect(() => {
    if (props.players.length > 0) {
      slotsService.setLayout(slots, props.players, layout);
    }
    if (![Layouts.promoUp, Layouts.promoDown].includes(layout)) {
      slotsService.resetCrop();
    }
    props.players.forEach((player, i) => {
      const visible = player.textVisible;
      streamer.setItemProperties(`text_player${i + 1}`, { visible });
      streamer.setItemProperties(`text_background_player${i + 1}`, { visible });
    });
    const activeSlots = slots.filter(({ connected }) => connected).length;
    [5, 6].forEach(slotsCount => {
      streamer.setItemProperties(`mask_promo_up_${slotsCount}`, {
        visible: layout === Layouts.promoUp && slotsCount === activeSlots,
      });
      streamer.setItemProperties(`mask_promo_down_${slotsCount}`, {
        visible: layout === Layouts.promoDown && slotsCount === activeSlots,
      });
    });
  }, [layout, slots, props.players, slotsService, streamer]);

  return (
    <LayoutContainer>
      {streamerConnected && <ZoomLayout slots={slots} onSlotsUpdate={setSlots} />}
      <StreamPreview />
      <StreamLayout layout={layout} onLayoutUpdate={setLayout} />
    </LayoutContainer>
  );
}

import { getCurrentPath } from 'navigation';
import { history } from 'navigation/browserHistory';
import { getRepository, getController } from 'framework';
import { getShowsFactory, getShowsBackend } from 'shows';
import { getShowTemplatesFactory, getShowTemplatesBackend } from 'showTemplates';
import { getLiveshowApi, viewerTypes } from 'services/liveshowApi';
import { getStreamer } from 'services/streamer';
import { getSequences } from 'services/sequences';
import { getNotification } from 'services/notification';
import { getMediaStorage } from 'services/mediaStorage';
import { getUseLiveshowApi } from 'hooks/liveshowApi';

export async function initServices(getClient) {
  const client = await getClient();

  const liveshowApi = getLiveshowApi({
    url: process.env.REACT_APP_LIVESHOW_API_URL,
    client: WebSocket,
    viewerType: viewerTypes.Staff,
  });

  const streamer = getStreamer({
    client: WebSocket,
  });

  const sequences = getSequences();

  const notification = getNotification();
  notification.init();

  const mediaStorage = getMediaStorage();

  const showsController = getController(getRepository(
    getShowsBackend(client),
    getShowsFactory(),
  ));
  const showTemplatesController = getController(getRepository(
    getShowTemplatesBackend(client),
    getShowTemplatesFactory(),
  ));

  const hooks = {
    useLiveshowApi: getUseLiveshowApi(liveshowApi),
  }

  return {
    showsController,
    showTemplatesController,
    navigation: {
      replace: history.replace,
      getCurrentPath,
    },
    liveshowApi,
    streamer,
    mediaStorage,
    hooks,
    sequences,
    notification,
  };
}

import React from 'react';
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

const MarkdownEditor = ({ placeholder, value, onChange }) => {
  return (
    <SimpleMDE
      value={value}
      onChange={value => onChange(null, { value })}
      options={{
        spellChecker: false,
      }}
    />
  );
};

export default MarkdownEditor;

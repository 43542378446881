import React from 'react';
import styled from 'styled-components';
import { Dropdown, Icon } from 'semantic-ui-react';
import { useCangrejoContext } from '../cangrejo/context';

const ZoomSlotContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.connected ? '#95ff7bb5' : '#ff3e3ed4'};
  border: 1px dashed;
`;

const Connection = styled(({ connected, ...props }) => (
  <div {...props}>
    {connected
      ? <Icon name='thumbs up' />
      : <Icon name='thumbs down' />
    }
  </div>
))`
  position: absolute;
  top: -1px;
  left: -1px;
  background-color: ${props => props.connected ? '#0b6b0b' : '#6b0b0b'};
  border-bottom-right-radius: 8px;
  padding: 0.1em 0.3em 0.2em 0.5em;
  cursor: pointer;
`;

const roleOptionsDefault = [
  {
    key: 'conductor',
    text: 'Presentador',
    value: 'conductor',
  },
  {
    key: 'media',
    text: 'Media',
    value: 'media',
  },
];

export default function ZoomSlot(props) {
  const {
    onConnectionChange,
    onRoleChange,
    ...restProps
  } = props;
  const cangrejoContext = useCangrejoContext();
  const [connected, setConnected] = React.useState(props.connected);

  const handleConnectionChange = () => {
    setConnected(oldConnected => {
      const newConnected = !oldConnected;
      if (typeof onConnectionChange === 'function') {
        onConnectionChange(newConnected);
      }
      return newConnected;
    });
  }
  const handleRoleChange = (_, { value }) => {
    if (typeof onRoleChange === 'function') {
      onRoleChange(value, props.role);
    }
  }

  const roleOptions = [
    ...roleOptionsDefault,
    ...cangrejoContext.players.map((player, i) => ({
      key: `player_${i}`,
      value: i,
      text: player.name,
    })),
  ];

  return (
    <ZoomSlotContainer {...restProps} connected={connected}>
      <Dropdown
        inline
        options={roleOptions}
        value={props.role}
        onChange={handleRoleChange}
      />
      <Connection connected={connected} onClick={handleConnectionChange} />
    </ZoomSlotContainer>
  );
}


import React, { useState } from 'react';
import S from 'utils/S';
import { useStream } from 'useStream';
import {
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Icon,
} from 'semantic-ui-react';
import { useBeiContext } from 'beiContext';

const Login = () => (
  <>
    Entrar
    <Icon style={{ marginLeft: 5 }} name='sign in' />
  </>
);

const messages = {
  'Incorrect username or password.': 'Ohhhh, contraseña incorrecta... ¡Ánimo, no hay error!',
  'Password attempts exceeded': '¡Te pasaste! Espera un ratito antes de probar más',
};

const Auth = () => {
  const [password, setPassword] = useState('');
  const { auth: { login, state } } = useBeiContext();
  const authState = useStream(state, S.Right('init'));
  const isLoading = S.either(() => false)(auth => auth === 'loggingIn' || auth === 'loggingOut')(authState);
  return (
    <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' color='teal' textAlign='center'>
          <Image src='/favicon-96x96.png' />Panel de administración
        </Header>
        <Form size='large' onSubmit={() => login(password)}>
          <Segment stacked>
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Contraseña'
              type='password'
              name='password'
              onChange={(e, { value }) => setPassword(value)}
            />
            <Form.Button loading={isLoading} disabled={password.length < 1} color='teal' fluid size='large'>
              <Login />
            </Form.Button>
            {S.isLeft(authState) && (
              <Header as='h4' color='red'>
                {S.either(auth => messages[auth] || auth)(() => null)(authState)}
              </Header>
            )}
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default Auth;

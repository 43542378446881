const viewerTypes = {
  Audience: 'audience',
  Streamer: 'streamer',
  MediaProvider: 'mediaProvider',
  Staff: 'staff',
};

const messageTypes = {
  Hello: 'hello',
  Ping: 'ping',
  Pong: 'pong',
  MediaSource: 'mediaSource',
  StreamSource: 'streamSource',
  SuggestionRequests: 'suggestionRequests',
  Suggestion: 'suggestion',
  Suggestions: 'suggestions',
  InsertedText: 'insertedText',
  ClearSuggestions: 'clearSuggestions',
  Scene: 'scene',
  ShowToStaff: 'showToStaff',
  Votes: 'votes',
  Player1: 'player1',
  Player2: 'player2',
  Player3: 'player3',
  Player4: 'player4',
  Player1Vote: 'player1Vote',
  Player2Vote: 'player2Vote',
  Player3Vote: 'player3Vote',
  Player4Vote: 'player4Vote',
  DonateVisible: 'donateVisible',
  VideoPopup: 'videoPopup',
  Layout: 'layout',
  Players: 'players',
  AudienceVoting: 'audienceVoting',
  Curtain: 'curtain',
  Overlays: 'overlays',
  Screenshot: 'screenshot',
  GetViewers: 'getViewers',
};

function getLiveshowApi({ url, client, viewerType }) {
  const mediaTypes = {
    VideoYoutube: 'videoYoutube',
    VideoS3: 'videoS3',
    MusicYoutube: 'musicYoutube',
    MusicS3: 'musicS3',
    MusicSpotify: 'musicSpotify',
  }
  let socket = { readyState: 3 };

  let watchdog = null;
  let ping = false;

  function init(listener) {
    function openConnection() {
      socket = new client(url);

      socket.addEventListener('open', function (event) {
        ping = false;
        sendMessage(messageTypes.Hello, viewerType);
      });

      socket.addEventListener('message', function (event) {
        const { type, data } = JSON.parse(event.data || '{}');
        switch (type) {
          case messageTypes.Hello: {
            if (data.mediaSources) {
              Object.entries(data.mediaSources).forEach(([mediaType, source]) => listener(mediaType, source));
            }
            if (data.suggestions) {
              listener(messageTypes.Suggestions, data.suggestions);
            }
            if (data.streamSource) {
              listener(messageTypes.StreamSource, data.streamSource);
            }
            if (data.suggestionRequests) {
              listener(messageTypes.SuggestionRequests, data.suggestionRequests);
            }
            if (data.streamerItems) {
              data.streamerItems.forEach(({ type, value }) => listener(type, value));
            }
            if (data.votes) {
              listener(messageTypes.Votes, data.votes);
            }
            if (data.donateVisible) {
              listener(messageTypes.DonateVisible, data.donateVisible);
            }
            if (data.videoPopup) {
              listener(messageTypes.VideoPopup, data.videoPopup);
            }
            if (data.layout) {
              listener(messageTypes.Layout, data.layout);
            }
            if (data.players) {
              listener(messageTypes.Players, data.players);
            }
            if (data.audienceVoting) {
              listener(messageTypes.AudienceVoting, data.audienceVoting);
            }
            if (data.overlays) {
              listener(messageTypes.Overlays, data.overlays);
            }
            break;
          }
          case messageTypes.Ping: {
            sendMessage(messageTypes.Pong);
            break;
          }
          case messageTypes.Pong: {
            ping = false;
            break;
          }
          default:
            listener(type, data);
            break;
        }
      });

      clearInterval(watchdog);
      watchdog = setInterval(() => {
        sendMessage(messageTypes.Ping);
        ping = true;
        setTimeout(() => {
          if (ping) {
            openConnection();
          }
        }, 2000);
      }, 10000);
    }

    openConnection();
  }

  function sendMessage(type, data) {
    if (socket.readyState === 1) {
      socket.send(JSON.stringify({
        type,
        ...(typeof data !== 'undefined' && data !== null) ? { data } : {},
      }));
    }
  }

  function close() {
    clearInterval(watchdog);
    if (socket.readyState < 2) {
      socket.close();
    }
  }

  const liveshowApi = {
    init,
    close,
    sendMessage,
    mediaTypes,
  };

  return ['init', 'close', 'sendMessage'].reduce((result, fn) => ({
    ...result,
    [fn]: liveshowApi[fn].bind(liveshowApi),
  }), {
    mediaTypes,
  });
}

export {
  getLiveshowApi,
  messageTypes,
  viewerTypes,
};

import gql from 'graphql-tag';

export const Shows = {
  fragments: {
    showData: gql`
      fragment ShowData on Show {
        id
        date
        title
        icon
        description
        locationName
        location {
          latitude
          longitude
        }
      }
    `,
  },
};

const list = gql`
  query ListShows($after: String, $before: String) {
    listShows(after: $after, before: $before) {
      items {
        ...ShowData
      }
      nextToken
    }
  }
  ${Shows.fragments.showData}
`;

const create = gql`
  mutation CreateShow($input: CreateShowInput!) {
    createShow(input: $input) {
      ...ShowData
    }
  }
  ${Shows.fragments.showData}
`;

const createBatch = gql`
  mutation CreateShows($input: [CreateShowInput]!) {
    createShows(input: $input) {
      ...ShowData
    }
  }
  ${Shows.fragments.showData}
`;

const update = gql`
  mutation UpdateShow($input: UpdateShowInput!) {
    updateShow(input: $input) {
      ...ShowData
    }
  }
  ${Shows.fragments.showData}
`;

const remove = gql`
  mutation RemoveShow($input: RemoveShowInput!) {
    removeShow(input: $input) {
      id
    }
  }
`;

const created = `
  subscription OnCreateShow {
    onCreateShow {
      items {
        ...ShowData
      }
      nextToken
    }
  }
  ${Shows.fragments.showData}
`;

const updated = `
  subscription OnUpdateShow {
    onUpdateShow {
      ...ShowData
    }
  }
  ${Shows.fragments.showData}
`;

const queries = {
  list,
  create,
  createBatch,
  update,
  remove,
  created,
  updated,
};

export default queries;

import S from 'utils/S';
import * as M from '@most/core';
import getStreamSource from 'utils/getStreamSource';
import { OPERATION } from './constants';

export const getOperation = S.fst;
export const getItem = S.snd;

const initialState = S.Pair(OPERATION.NONE)(S.Nothing);

export function getController(repository) {
  const { stream: createStream, push: create } = getStreamSource();
  const { stream: selectStream, push: select } = getStreamSource();
  const { stream: removeSignal, push: remove } = getStreamSource();
  const { stream: saveSignal, push: save } = getStreamSource();

  const current = M.multicast(S.pipe([
    M.startWith(initialState),
  ])(M.mergeArray([
    M.map((...args) => S.Pair(OPERATION.CREATE)(S.Just(repository.create(...args))), createStream),
    M.map((request) => {
      if (!request) return initialState;
      const itemId = typeof request === 'string' ? request : request.itemId;
      const item = repository.get(itemId);
      const operation = request.operation || OPERATION.UPDATE;
      return S.Pair(operation)(S.Just(item));
    }, selectStream),
    M.chain(item => M.fromPromise(repository.remove(item).then(() => initialState)))(removeSignal),
  ])));

  const saveState = S.pipe([
    M.snapshot(item => S.map(itemData => repository.save(S.fst(item), itemData))(S.snd(item)))(current), // Call repository.save
    M.chain(S.pipe([ // Every save attempt will trigger a new sub-stream
      S.maybeToNullable,
      M.fromPromise, // Wait for the save Promise
      M.constant(S.Right('success')), // Resolve with Right('success') if Promise is resolved
      M.tap(() => select(null)), // Unselect current item if save was successful
      M.recoverWith(error => M.now(S.Left(error))), // The stream will be set to Left(error) if the Promise is rejected
      M.startWith(S.Right('processing')), // Set the stream to Right('processing') while waiting the save promise
    ])),
    M.merge(M.constant(S.Right('idle'))(M.filter(S.compose(S.equals(OPERATION.NONE))(S.fst), current))), // Set the stream back to 'idle' when no item is selected
  ])(saveSignal);

  return {
    current,
    create,
    remove,
    select,
    save,
    saveState,
    watch: repository.watch,
    constants: {
      OPERATION,
    },
  };
};

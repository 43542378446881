import toRegex from 'path-to-regexp';

function matchURI(path, uri) {
  const keys = [];
  const pattern = toRegex(path, keys); // TODO: Use caching
  const match = pattern.exec(uri);
  if (!match) return null;
  const params = Object.create(null);
  for (let i = 1; i < match.length; i++) {
    params[keys[i - 1].name] =
      match[i] !== undefined ? match[i] : undefined;
  }
  return params;
}

async function resolve(routes, context) {
  for (const route of routes) {
    const uri = context.location.pathname;
    const params = matchURI(route.path, uri);
    if (!params) continue;
    if (route.redirect) {
      context.history.replace(route.redirect);
      return null;
    }
    if (route.private && !context.loggedIn) {
      const error = new Error('Unauthorized');
      error.status = 401;
      throw error;
    }
    const result = await route.action({ ...context, params });
    if (result) return result;
  }
  const error = new Error('Not found');
  error.status = 404;
  throw error;
}

const router = {
  resolve,
};

export default router;

import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import StreamSlot from '../components/StreamSlot';
import { Layouts } from '../services/slots';
import { useCangrejoContext } from '../cangrejo/context';
import countdownImg from '../cangrejo/countdown.png';
import coupleImg from '../cangrejo/couple.png';
import generalImg from '../cangrejo/general.png';
import introImg from '../cangrejo/intro.png';
import promoImg from '../cangrejo/promo.png';
import promoUpImg from '../cangrejo/promoUp.png';
import promoDownImg from '../cangrejo/promoDown.png';

const StreamLayoutContainer = styled.div`
  position: relative;
  background-color: #1e1e1f;
  color: #a0a0a0;
  border-radius: 0 0 4px 4px;
`;

const LayoutsRoot = styled.div`
  display: flex;
`;

const LayoutPicker = styled.div`
  height: calc(720px/3);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-template-areas: 
    ". . intro"
    "promoUp promoDown general"
    ". . jugo"
    ". . pensamientos"
    ". . conga";
`;

const LayoutImage = styled.img`
  height: 48px;
  cursor: pointer;
  border: 2px solid #334554;
  transition: border-color 0.2s ease;
`;
const getBorderColor = layout => props => (
  props.layout === layout ? 'border-color: #4DE022;' : props.nextLayout === layout ? 'border-color: #45B8CE;' : ''
)
const LayoutImageIntro = styled(LayoutImage)`
  grid-area: intro;
  ${getBorderColor(Layouts.intro)}
`;
const LayoutImageGeneral = styled(LayoutImage)`
  grid-area: general;
  ${getBorderColor(Layouts.general)}
`;
const LayoutImagePromoUp = styled(LayoutImage)`
  grid-area: promoUp;
  ${getBorderColor(Layouts.promoUp)}
`;
const LayoutImagePromoDown = styled(LayoutImage)`
  grid-area: promoDown;
  ${getBorderColor(Layouts.promoDown)}
`;
const LayoutImageJugo = styled(LayoutImage)`
  grid-area: jugo;
  ${getBorderColor(Layouts.couple)}
`;
const LayoutImagePensamientos = styled(LayoutImage)`
  grid-area: pensamientos;
  ${getBorderColor(Layouts.promo)}
`;
const LayoutImageConga = styled(LayoutImage)`
  grid-area: conga;
  ${getBorderColor(Layouts.countdown)}
`;

const LayoutCommon = styled.div`
  width: calc(1280px/3);
  height: calc(720px/3);
  display: grid;
`;

const LayoutIntro = styled(LayoutCommon)`
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: repeat(1, 1fr);
  grid-template-areas: "conductor";
`;
const LayoutGeneral = styled(LayoutCommon)`
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: repeat(4, 1fr);
  grid-template-areas: 
    "idle1 .         idle2"
    "idle1 conductor idle2"
    "idle3 conductor idle4"
    "idle3 .         idle4";
`;
const LayoutCouple = styled(LayoutCommon)`
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: repeat(3, 1fr);
  grid-template-areas: 
    "player1   player1   player2   player2"
    "player1   player1   player2   player2"
    "idle1     conductor conductor idle2";
`;
const LayoutPromo = styled(LayoutCommon)`
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: repeat(3, 1fr);
  grid-template-areas: 
    "player1   player1 media media"
    "player1   player1 media media"
    "conductor idle1   idle2 idle3";
`;
const LayoutCountdown = styled(LayoutCommon)`
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: repeat(3, 1fr);
  grid-template-areas: 
    "conductor player1 player1 player1"
    "conductor player1 player1 player1"
    ".         idle1   idle2   idle3";
`;
const LayoutPromoUp = styled(LayoutCommon)`
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: repeat(4, 1fr);
  grid-template-areas: 
    "idle1 media     idle2"
    "idle1 media     idle2"
    "idle3 conductor idle4"
    "idle3 conductor idle4";
`;
const LayoutPromoDown = styled(LayoutCommon)`
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: repeat(4, 1fr);
  grid-template-areas: 
    "idle1 conductor idle2"
    "idle1 conductor idle2"
    "idle3 media     idle4"
    "idle3 media     idle4";
`;

const layoutMap = {
  [Layouts.intro]: LayoutIntro,
  [Layouts.general]: LayoutGeneral,
  [Layouts.promoUp]: LayoutPromoUp,
  [Layouts.promoDown]: LayoutPromoDown,
  [Layouts.couple]: LayoutCouple,
  [Layouts.promo]: LayoutPromo,
  [Layouts.countdown]: LayoutCountdown,
};

const roleReassignments = {
  [Layouts.intro]: {
    [Layouts.intro]: {},
    [Layouts.general]: {},
    [Layouts.promoUp]: {},
    [Layouts.promoDown]: {},
    [Layouts.couple]: {
      idle1: 'player1',
      idle2: 'player2',
      idle3: 'idle1',
      idle4: 'idle2',
    },
    [Layouts.promo]: {
      idle1: 'player1',
      idle2: 'idle1',
      idle3: 'idle2',
      idle4: 'idle3',
    },
    [Layouts.countdown]: {
      idle1: 'player1',
      idle2: 'idle1',
      idle3: 'idle2',
      idle4: 'idle3',
    },
  },
  [Layouts.general]: {
    [Layouts.intro]: {},
    [Layouts.general]: {},
    [Layouts.promoUp]: {},
    [Layouts.promoDown]: {},
    [Layouts.couple]: {
      idle1: 'player1',
      idle2: 'player2',
      idle3: 'idle1',
      idle4: 'idle2',
    },
    [Layouts.promo]: {
      idle1: 'player1',
      idle2: 'idle1',
      idle3: 'idle2',
      idle4: 'idle3',
    },
    [Layouts.countdown]: {
      idle1: 'player1',
      idle2: 'idle1',
      idle3: 'idle2',
      idle4: 'idle3',
    },
  },
  [Layouts.promoUp]: {
    [Layouts.intro]: {},
    [Layouts.general]: {},
    [Layouts.promoUp]: {},
    [Layouts.promoDown]: {},
    [Layouts.couple]: {
      idle1: 'player1',
      idle2: 'player2',
      idle3: 'idle1',
      idle4: 'idle2',
    },
    [Layouts.promo]: {
      idle1: 'player1',
      idle2: 'idle1',
      idle3: 'idle2',
      idle4: 'idle3',
    },
    [Layouts.countdown]: {
      idle1: 'player1',
      idle2: 'idle1',
      idle3: 'idle2',
      idle4: 'idle3',
    },
  },
  [Layouts.promoDown]: {
    [Layouts.intro]: {},
    [Layouts.general]: {},
    [Layouts.promoUp]: {},
    [Layouts.promoDown]: {},
    [Layouts.couple]: {
      idle1: 'player1',
      idle2: 'player2',
      idle3: 'idle1',
      idle4: 'idle2',
    },
    [Layouts.promo]: {
      idle1: 'player1',
      idle2: 'idle1',
      idle3: 'idle2',
      idle4: 'idle3',
    },
    [Layouts.countdown]: {
      idle1: 'player1',
      idle2: 'idle1',
      idle3: 'idle2',
      idle4: 'idle3',
    },
  },
  [Layouts.couple]: {
    [Layouts.intro]: {
      player1: 'idle1',
      player2: 'idle2',
      idle1: 'idle3',
      idle2: 'idle4',
    },
    [Layouts.general]: {
      player1: 'idle1',
      player2: 'idle2',
      idle1: 'idle3',
      idle2: 'idle4',
    },
    [Layouts.promoUp]: {
      player1: 'idle1',
      player2: 'idle2',
      idle1: 'idle3',
      idle2: 'idle4',
    },
    [Layouts.promoDown]: {
      player1: 'idle1',
      player2: 'idle2',
      idle1: 'idle3',
      idle2: 'idle4',
    },
    [Layouts.couple]: {
    },
    [Layouts.promo]: {
      player1: 'player1',
      player2: 'idle1',
      idle1: 'idle2',
      idle2: 'idle3',
    },
    [Layouts.countdown]: {
      player1: 'player1',
      player2: 'idle1',
      idle1: 'idle2',
      idle2: 'idle3',
    },
  },
  [Layouts.promo]: {
    [Layouts.intro]: {
      player1: 'idle1',
      idle1: 'idle2',
      idle2: 'idle3',
      idle3: 'idle4',
    },
    [Layouts.general]: {
      player1: 'idle1',
      idle1: 'idle2',
      idle2: 'idle3',
      idle3: 'idle4',
    },
    [Layouts.promoUp]: {
      player1: 'idle1',
      idle1: 'idle2',
      idle2: 'idle3',
      idle3: 'idle4',
    },
    [Layouts.promoDown]: {
      player1: 'idle1',
      idle1: 'idle2',
      idle2: 'idle3',
      idle3: 'idle4',
    },
    [Layouts.couple]: {
      player1: 'player1',
      idle1: 'player2',
      idle2: 'idle1',
      idle3: 'idle2',
    },
    [Layouts.promo]: {},
    [Layouts.countdown]: {},
  },
  [Layouts.countdown]: {
    [Layouts.intro]: {
      player1: 'idle1',
      idle1: 'idle2',
      idle2: 'idle3',
      idle3: 'idle4',
    },
    [Layouts.general]: {
      player1: 'idle1',
      idle1: 'idle2',
      idle2: 'idle3',
      idle3: 'idle4',
    },
    [Layouts.promoUp]: {
      player1: 'idle1',
      idle1: 'idle2',
      idle2: 'idle3',
      idle3: 'idle4',
    },
    [Layouts.promoDown]: {
      player1: 'idle1',
      idle1: 'idle2',
      idle2: 'idle3',
      idle3: 'idle4',
    },
    [Layouts.couple]: {
      player1: 'player1',
      idle1: 'player2',
      idle2: 'idle1',
      idle3: 'idle2',
    },
    [Layouts.promo]: {},
    [Layouts.countdown]: {},
  },
};

export default function StreamLayout(props) {
  const cangrejoContext = useCangrejoContext();

  const [currentLayout, setCurrentLayout] = React.useState(props.layout);
  const [currentPlayers, setCurrentPlayers] = React.useState(cangrejoContext.players);
  const Layout = layoutMap[currentLayout] || LayoutIntro;

  const selectedLayout = (layout) => () => {
    setCurrentLayout(layout);
    const roleReassignment = roleReassignments[currentLayout][layout];
    setCurrentPlayers(current => current.map(player => ({
      ...player,
      role: roleReassignment[player.role] || player.role,
    })));
  };

  const setPlayerRole = (playerIndex, role) => setCurrentPlayers(current => {
    const newPlayers = [...current];
    const oldPlayerIndex = newPlayers.findIndex(player => player.role === role);
    if (oldPlayerIndex > -1) {
      newPlayers[oldPlayerIndex].role = newPlayers[playerIndex].role;
    }
    newPlayers[playerIndex].role = role;
    return newPlayers;
  });

  React.useEffect(() => {
    setCurrentPlayers(cangrejoContext.players);
  }, [cangrejoContext.players]);

  React.useEffect(() => {
    if (props.layout !== currentLayout) {
      setCurrentLayout(props.layout);
      const roleReassignment = roleReassignments[currentLayout][props.layout];
      setCurrentPlayers(current => current.map(player => ({
        ...player,
        role: roleReassignment[player.role] || player.role,
      })));
    }
  }, [props.layout]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasChanges = currentLayout !== props.layout || currentPlayers !== cangrejoContext.players; 
  const streamSlotProps = {
    players: currentPlayers,
    setPlayerRole,
  };

  return (
    <StreamLayoutContainer>
      <LayoutsRoot>
        <LayoutPicker>
          <LayoutImageIntro layout={props.layout} nextLayout={currentLayout} src={introImg} alt='Presentación' onClick={selectedLayout(Layouts.intro)} />
          <LayoutImageGeneral layout={props.layout} nextLayout={currentLayout} src={generalImg} alt='General' onClick={selectedLayout(Layouts.general)} />
          <LayoutImagePromoUp layout={props.layout} nextLayout={currentLayout} src={promoUpImg} alt='PromoUp' onClick={selectedLayout(Layouts.promoUp)} />
          <LayoutImagePromoDown layout={props.layout} nextLayout={currentLayout} src={promoDownImg} alt='PromoDown' onClick={selectedLayout(Layouts.promoDown)} />
          <LayoutImageJugo layout={props.layout} nextLayout={currentLayout} src={coupleImg} alt='Pareja' onClick={selectedLayout(Layouts.couple)} />
          <LayoutImagePensamientos layout={props.layout} nextLayout={currentLayout} src={promoImg} alt='Caipirihna' onClick={selectedLayout(Layouts.promo)} />
          <LayoutImageConga layout={props.layout} nextLayout={currentLayout} src={countdownImg} alt='Jalisco' onClick={selectedLayout(Layouts.countdown)} />
        </LayoutPicker>
        <Layout>
          {({
            [Layouts.intro]: (
              <>
                <StreamSlot type='conductor'>
                  Presentador
                </StreamSlot>
              </>
            ),
            [Layouts.general]: (
              <>
                <StreamSlot type='conductor'>
                  Presentador
                </StreamSlot>
                <StreamSlot type='idle1' {...streamSlotProps} />
                <StreamSlot type='idle2' {...streamSlotProps} />
                <StreamSlot type='idle3' {...streamSlotProps} />
                <StreamSlot type='idle4' {...streamSlotProps} />
              </>
            ),
            [Layouts.promoUp]: (
              <>
                <StreamSlot type='conductor'>
                  Presentador
                </StreamSlot>
                <StreamSlot type='media'>
                  Media
                </StreamSlot>
                <StreamSlot type='idle1' {...streamSlotProps} />
                <StreamSlot type='idle2' {...streamSlotProps} />
                <StreamSlot type='idle3' {...streamSlotProps} />
                <StreamSlot type='idle4' {...streamSlotProps} />
              </>
            ),
            [Layouts.promoDown]: (
              <>
                <StreamSlot type='conductor'>
                  Presentador
                </StreamSlot>
                <StreamSlot type='media'>
                  Media
                </StreamSlot>
                <StreamSlot type='idle1' {...streamSlotProps} />
                <StreamSlot type='idle2' {...streamSlotProps} />
                <StreamSlot type='idle3' {...streamSlotProps} />
                <StreamSlot type='idle4' {...streamSlotProps} />
              </>
            ),
            [Layouts.couple]: (
              <>
                <StreamSlot type='conductor'>
                  Presentador
                </StreamSlot>
                <StreamSlot type='player1' {...streamSlotProps} />
                <StreamSlot type='player2' {...streamSlotProps} />
                <StreamSlot type='idle1' {...streamSlotProps} />
                <StreamSlot type='idle2' {...streamSlotProps} />
              </>
            ),
            [Layouts.promo]: (
              <>
                <StreamSlot type='conductor'>
                  Presentador
                </StreamSlot>
                <StreamSlot type='media'>
                  Media
                </StreamSlot>
                <StreamSlot type='player1' {...streamSlotProps} />
                <StreamSlot type='idle1' {...streamSlotProps} />
                <StreamSlot type='idle2' {...streamSlotProps} />
                <StreamSlot type='idle3' {...streamSlotProps} />
              </>
            ),
            [Layouts.countdown]: (
              <>
                <StreamSlot type='conductor'>
                  Presentador
                </StreamSlot>
                <StreamSlot type='player1' {...streamSlotProps} />
                <StreamSlot type='idle1' {...streamSlotProps} />
                <StreamSlot type='idle2' {...streamSlotProps} />
                <StreamSlot type='idle3' {...streamSlotProps} />
              </>
            )
          }[currentLayout])}
        </Layout>
      </LayoutsRoot>
      <Button.Group style={{ float: 'right' }}>
        <Button style={{ borderTopLeftRadius: 0 }} disabled={!hasChanges} onClick={() => {
          setCurrentPlayers(cangrejoContext.players);
          setCurrentLayout(props.layout);
        }}>
          Cancelar
        </Button>
        <Button.Or text='o' />
        <Button disabled={!hasChanges} positive onClick={() => {
          cangrejoContext.setPlayers(currentPlayers);
          if (typeof props.onLayoutUpdate === 'function') {
            props.onLayoutUpdate(currentLayout);
          }
        }}>
          Aplicar
        </Button>
      </Button.Group>
    </StreamLayoutContainer>
  )
}

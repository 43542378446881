import React from 'react';
import styled from 'styled-components';
import ZoomSlot from './ZoomSlot';

const ZoomLayoutContainer = styled.div`
  width: 512px;
  background-color: #1e1e1f;
  display: grid;
  grid: repeat(2, 100px) / repeat(3, 160px);
  grid-gap: 8px;
  padding: 8px;
  justify-items: center;
  align-items: center;
`;

export default function ZoomLayout(props) {
  return (
    <ZoomLayoutContainer>
      {props.slots.map((slot, i) => (
        <ZoomSlot
          key={`${slot.role}_${i}`}
          role={slot.role}
          connected={slot.connected}
          onConnectionChange={connected => {
            const newSlots = [...props.slots];
            newSlots[i] = {
              ...slot,
              connected,
            };
            props.onSlotsUpdate(newSlots);
          }}
          onRoleChange={(newRole, oldRole) => {
            if (typeof props.onSlotsUpdate === 'function') {
              const newSlots = [...props.slots];
              const newIndex = newSlots.findIndex(({ role }) => role === newRole);
              const oldIndex = newSlots.findIndex(({ role }) => role === oldRole);
              const oldSlot = newSlots[oldIndex];
              const newSlot = newSlots[newIndex];
              newSlots[oldIndex] = newSlot;
              newSlots[newIndex] = oldSlot;
              props.onSlotsUpdate(newSlots);
            }
          }}
        />
      ))}
    </ZoomLayoutContainer>
  );
}

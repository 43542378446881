import React from 'react';
import styled from 'styled-components';
import { useBeiContext } from 'beiContext';
import { Button, Container, Header, Input, Segment, Checkbox } from 'semantic-ui-react';
import { messageTypes } from '../services/liveshowApi';

const StreamerContainer = styled(Container)`
  margin-top: 1em;
`;

const broadcastScreenshots = ({ streamer, liveshowApi }) => {
  async function tick() {
    const screenshot = await streamer.getScreenshot();
    liveshowApi.sendMessage(messageTypes.Screenshot, screenshot);
  }
  tick();
  const interval = setInterval(tick, 333);
  return () => clearInterval(interval);
};

const pollViewers = ({ liveshowApi }) => {
  function tick() {
    liveshowApi.sendMessage(messageTypes.GetViewers);
  }
  tick();
  const interval = setInterval(tick, 10000);
  return () => clearInterval(interval);
};

function Streamer() {
  const {
    streamer,
    liveshowApi,
  } = useBeiContext();
  const [host, setHost] = React.useState('localhost');
  const [port, setPort] = React.useState(4444);
  const [isConnected, setIsConnected] = React.useState(false);

  const [previewRunning, setPreviewRunning] = React.useState(false);
  React.useEffect(() => {
    if (previewRunning) {
      return broadcastScreenshots({ streamer, liveshowApi });
    }
  }, [previewRunning, streamer, liveshowApi]);

  const [pollingViewers, setPollViewers] = React.useState(false);
  React.useEffect(() => {
    if (pollingViewers) {
      return pollViewers({ liveshowApi });
    }
  }, [pollingViewers, liveshowApi]);

  function handleButtonPress() {
    if (isConnected) {
      streamer.close();
    } else {
      streamer.init({ host, port, onConnected: setIsConnected });
    }
  }

  return (
    <StreamerContainer>
      <Segment>
        <Header>Streamer</Header>
        <Segment>
          <Input
            placeholder="host"
            value={host}
            onChange={({ target: { value } }) => setHost(value)}
          />
          <Input
            placeholder="port"
            value={port}
            onChange={({ target: { value } }) => setPort(value)}
          />
          <Button
            positive={isConnected}
            content={isConnected ? "Disconnect from streaming server" : "Connect to streaming server" }
            onClick={handleButtonPress}
          />
          <Checkbox
            label='Preview source'
            checked={previewRunning}
            onChange={(_, { checked }) => setPreviewRunning(checked)}
            disabled={!isConnected}
          />
          <Checkbox
            label='Poll viewers'
            checked={pollingViewers}
            onChange={(_, { checked }) => setPollViewers(checked)}
          />
        </Segment>
      </Segment>
    </StreamerContainer>
  );
}

export {
  Streamer,
};

import { useEffect, useState } from 'react';

function usePersistentState(key, initialState) {
  const state = useState(JSON.parse(localStorage.getItem(key)) || initialState);

  const [value] = state;
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return state;
}

export { usePersistentState };

import React from 'react';
import { useBeiContext } from 'beiContext';
import styled from 'styled-components';
import { messageTypes } from '../services/liveshowApi';

const AudienceCounterBase = styled.div`
  position: fixed;
  height: 2em;
  bottom: 0;
  right: 0;
  background-color: #414141;
  z-index: 1;
  font-family: sans-serif;
  color: whitesmoke;
  font-size: 24px;
  display: flex;
  align-items: center;
  padding: 0.5em;
  border: 3px solid #b90b0b;
  border-right-width: 0;
  border-bottom-width: 0;
  border-top-left-radius: 16px;
`;

function AudienceCounter(props) {
  const {
    hooks: { useLiveshowApi },
  } = useBeiContext();

  const [viewers] = useLiveshowApi(messageTypes.GetViewers, []);

  const now = Date.now();
  const audience = viewers.filter(({ lastPing, viewerType }) => (
    viewerType === 'audience' && ((now - lastPing) < 30000)
  ));
  const audienceText = `Audiencia: ${audience.length}`;

  return (
    <AudienceCounterBase>
      {audienceText}
    </AudienceCounterBase>
  );
}

export default AudienceCounter;

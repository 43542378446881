import React, { useState } from 'react';
import moment from 'moment';
import { Input } from 'semantic-ui-react'
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy HH:MM', { minYear: (new Date()).getFullYear(), maxYear: 3019 });

const dateTextStyle = {
  color: 'rgba(0, 0, 0, 0.3)',
  fontStyle: 'italic',
};

const DateInput = ({ value, onChange = () => {}, ...props }) => {
  let setDate;
  const DateText = props => {
    var [date, setState] = useState(value ? moment(value) : null);
    setDate = setState;

    return date ? (
      <div style={dateTextStyle}>
        {date.format('dddd D [de] MMMM')}
      </div>
    ) : null;
  };
  return (
    <div>
      <Input
        children={
          <MaskedInput
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/]}
            keepCharPositions
            placeholder="26/11/1988 02:15"
            pipe={autoCorrectedDatePipe}
            onChange={({ target: { value } }) => {
              const date = moment(value, 'DD/MM/YYYY HH:mm');
              if (date.isValid()) {
                setDate(date);
                onChange(date.toDate());
              }
            }}
            {...value
              ? { value: moment(value).format('DD/MM/YYYY HH:mm') }
              : {}
            }
          />
        }
        {...props}
      />
      <DateText />
    </div>
  );
};

export default DateInput;

import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Button } from 'semantic-ui-react';

const progress = keyframes`
  0%   { transform: scaleX(0); opacity: 1; }
  90%  { transform: scaleX(0.9); opacity: 1; }
  100% { transform: scaleX(1); opacity: 0; }
`;

const ProgressButtonBase = styled(({ inProgress, duration, ...props }) => <Button {...props} />)`
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 4px;
    background-color: #21ba45;
    width: 100%;
    left: 0;
    border-bottom-right-radius: 4px;
    transform-origin: 0% 100%;
    opacity: 0;
    ${props => props.inProgress && css`
      animation: ${progress} ${props => props.duration}ms linear;
    `};
  }
`;

function ProgressButton(props) {
  const [inProgress, setInProgress] = React.useState(false);

  const onClick = (...args) => {
    if (props.duration > 0) {
      setInProgress(true);
      setTimeout(() => setInProgress(false), props.duration);
    }
    if (typeof props.onClick === 'function') {
      props.onClick(...args);
    }
  };

  return (
    <ProgressButtonBase {...props} duration={props.duration} onClick={onClick} inProgress={inProgress} />
  )
}

export default ProgressButton;
export { ProgressButtonBase };

import 'semantic-ui-css/semantic.min.css'
import './index.css';
import * as serviceWorker from './serviceWorker';
// Services
import { beiRender } from 'beiRender';
import { auth } from 'auth';
import { history } from 'navigation/browserHistory';
import { getNavigation } from 'navigation';
// Pages
import Auth from 'pages/Auth';
import NotFound from 'pages/NotFound';

const container = document.getElementById('root');
getNavigation({
  render: beiRender(container),
  renderError: {
    401: () => beiRender(container)(Auth),
    404: () => beiRender(container)(NotFound),
  },
  history,
  authState: auth.state,
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

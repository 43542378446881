import React from 'react';
import { Button, Input } from 'semantic-ui-react'
import { usePersistentState } from './usePersistentState';

function PersistentInput(props) {
  const [value, setValue] = usePersistentState(props.persistenceKey, '');

  return (
    <Input
      fluid
      label={<Button positive content="Usar" onClick={() => props.onSubmit(value)}/>}
      labelPosition='right'
      placeholder="Preparar texto aquí..."
      value={value}
      onChange={({ target: { value } }) => setValue(value)}
    />
  );
}

export { PersistentInput };

import { createBrowserHistory } from 'history';
import { toStream } from 'utils/getStreamSource';

const {
  push,
  replace,
  listen,
  location,
} = createBrowserHistory();

export const history = {
  push,
  replace: path => setImmediate(() => replace(path)),
  location: toStream(listen, location),
};

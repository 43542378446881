import React from 'react'
import { history } from './browserHistory';

const onClick = e => {
  e.preventDefault();
  history.push({
    pathname: e.currentTarget.pathname,
    search: e.currentTarget.search,
  });
};

const Link = ({ to, children }) => (
  <a href={to} onClick={onClick}>
    {children}
  </a>
);

export default Link;

import AWSAppSyncClient from 'aws-appsync';
import aws_exports from './aws-exports';
import { getCurrentCredentials } from './auth';

export const appsyncInit = () => {
  const client = new AWSAppSyncClient({
    url: aws_exports.aws_appsync_graphqlEndpoint,
    region: aws_exports.aws_appsync_region,
    auth: {
      type: aws_exports.aws_appsync_authenticationType,
      credentials: getCurrentCredentials,
    },
    disableOffline: true,
  });

  return client.hydrated();
};

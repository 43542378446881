import React from 'react';
import { Image } from 'semantic-ui-react';
import { useBeiContext } from 'beiContext';
import './show.css';

const prependZero = n => (n < 10 ? `0${n}` : n);

const t = {
  es: {
    allShows: 'todos los shows',
    doorOpening: 'Apertura',
    reserve: 'Reserva',
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    days: ['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sá'],
    dayNames: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
  },
  en: {
    allShows: 'back to shows',
    doorOpening: 'Door opening',
    reserve: 'Reserve',
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    days: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
    dayNames: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Sábado',
    ],
  },
  de: {
    allShows: 'zurück',
    doorOpening: 'Tür offnung',
    reserve: 'Reservieren',
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    days: ['so', 'mo', 'di', 'mi', 'do', 'fr', 'sa'],
    dayNames: [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ],
  },
};

const storageUrl = `https://${process.env.REACT_APP_MEDIA_URL}/public`;
// TODO Language switch
const locale = 'es';
const Show = ({ show: { id, title, date, locationName, icon } }) => {
  const dayName = t[locale].days[date.getDay()];
  const dayNumber = prependZero(date.getDate());
  const hour = `${prependZero(date.getHours())}:${prependZero(
    date.getMinutes(),
  )}h`;
  const {
    showsController: { select },
  } = useBeiContext();
  return (
    <div className="show" onClick={() => select(id)}>
      <div className="day">
        <div className="day-name">{dayName}</div>
        <div className="day-number">{dayNumber}</div>
      </div>
      <div className="title-place">
        <div className="title">{title}</div>
        <div className="place">
          <div className="hour">{hour}</div>
          <div className="location-name">{locationName || ''}</div>
        </div>
      </div>
      <Image
        style={{ width: '3rem', height: 'auto' }}
        src={`${storageUrl}/${icon}`}
      />
    </div>
  );
};

export default Show;

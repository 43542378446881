import React from 'react';
import {
  Container,
  Grid,
  Header,
  Input,
  Segment,
} from 'semantic-ui-react'
import { useBeiContext } from 'beiContext';
import { messageTypes } from '../services/liveshowApi';

function Streaming() {
  const { hooks: { useLiveshowApi } } = useBeiContext();
  const [streamSource, setStreamSource] = useLiveshowApi(messageTypes.StreamSource, '');
  const [input, setInput] = React.useState(streamSource);

  React.useEffect(() => { setInput(streamSource); }, [streamSource]);

  const handleInput = ({ target: { value } }) => setInput(value);
  const handleSubmit = () => setStreamSource(input);

  return (
    <Container>
      <Segment>
        <Header style={{ marginTop: 'auto', marginBottom: 'auto' }} as="h3">Streaming</Header>
        <Grid.Column width={5}>
          <Input
            placeholder={'Origen del streaming...'}
            onChange={handleInput}
            action={{ color: 'orange', content: 'Establecer streaming', onClick: handleSubmit }}
            value={input}
          />
        </Grid.Column>
        <Grid.Column width={5}>
        </Grid.Column>
      </Segment>
    </Container>
  );
}

export default Streaming;

import React from 'react'; import { Header, Table } from 'semantic-ui-react'
import styled from 'styled-components';
import { useBeiContext } from 'beiContext';
import AdminLayout from 'components/AdminLayout';
import './liveshow.css';
import { Intro, Jugo, Pensamientos, Conga, Countdown, Outro } from 'components/Curtain';
import StreamPreview from 'components/StreamPreview';
import { messageTypes } from '../services/liveshowApi';

const Cell = styled(Table.Cell)`
  font-size: 16px;
  font-weight: 700;
`;

const Cortinas = styled.div`
  display: flex;
  justify-content: space-between;
`;

const voteMessages = [
  messageTypes.Player1Vote,
  messageTypes.Player2Vote,
  messageTypes.Player3Vote,
  messageTypes.Player4Vote,
];

function CangrejoConductor() {
  const { hooks: { useLiveshowApi } } = useBeiContext();

  const [players] = useLiveshowApi(messageTypes.Players, []);
  const [votes] = useLiveshowApi(messageTypes.Votes, []);
  const [runningCurtain] = useLiveshowApi(messageTypes.Curtain, '');

  const playerVotes = votes.reduce((result, vote) => {
    const voteIndex = voteMessages.indexOf(vote);
    if (voteIndex > -1) {
      result[voteIndex] += 1;
    }
    return result;
  }, [0, 0, 0, 0]);

  return (
    <AdminLayout>
      <Table celled padded unstackable>
	<Table.Header>
	  <Table.Row>
	    <Table.HeaderCell singleLine>Jugador</Table.HeaderCell>
	    <Table.HeaderCell>Votos</Table.HeaderCell>
	    <Table.HeaderCell>Puntos</Table.HeaderCell>
	    <Table.HeaderCell>Percebes disponibles</Table.HeaderCell>
	  </Table.Row>
	</Table.Header>
	<Table.Body>
          {(players || []).map((player, i) => (
            <Table.Row key={i}>
              <Table.Cell style={{ backgroundColor: player.color }}>
                <Header as='h2' textAlign='center'>
                  {player.name}
                </Header>
              </Table.Cell>
              <Cell>
                {playerVotes[i]}
              </Cell>
              <Cell>
                 {player.score}
              </Cell>
              <Cell>
                {player.percebes}
              </Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Table celled padded unstackable>
	<Table.Header>
	  <Table.Row>
	    <Table.HeaderCell singleLine>Cortinas</Table.HeaderCell>
	  </Table.Row>
	</Table.Header>
	<Cortinas>
          <Intro runningCurtain={runningCurtain} />
          <Jugo runningCurtain={runningCurtain} />
          <Pensamientos runningCurtain={runningCurtain} />
          <Conga runningCurtain={runningCurtain} />
          <Countdown runningCurtain={runningCurtain} />
          <Outro runningCurtain={runningCurtain} />
	</Cortinas>
      </Table>
      <div style={{ marginLeft: '1em' }}>
        <StreamPreview />
      </div>
    </AdminLayout>
  );
}

export default CangrejoConductor;

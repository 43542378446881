import * as M from  '@most/core';
import S, { $ } from  'utils/S';
import { fromObservable } from 'utils/getStreamSource';
import showQueries, { Shows } from './queries';

const parseItem = ({ date, ...rest }) => ({
  date: new Date(date),
  ...rest,
});
const sortItems = ({ date: idA }, { date: idB }) => idB < idA;

export function getShowsBackend(client) {
  let watchVariables = {};

  return {
    get(showId) {
      const { __typename, ...show } = client.readFragment({
        id: `Show:${showId}`,
        fragment: Shows.fragments.showData,
      });
      return show;
    },
    create: show => client.mutate({
      mutation: showQueries.create,
      variables: { input: show },
      update: (proxy, response) => S.map(createShow => {
        const query = showQueries.list;
        const variables = watchVariables;
        const data = proxy.readQuery({ query, variables });
        if (!data.listShows.items.find(show => show.id === createShow.id)) {
          data.listShows.items.push(createShow);
          proxy.writeQuery({ query, variables, data });
        }
        return null;
      })(S.gets(S.is($.Object))(['data', 'createShow'])(response)),
    }),
    remove: ({ id }) => client.mutate({
      mutation: showQueries.remove,
      variables: { input: { id } },
      update: (proxy, response) => S.map(({ id }) => {
        const query = showQueries.list;
        const variables = watchVariables;
        const data = proxy.readQuery({ query, variables });
        data.listShows.items = data.listShows.items.filter(item => item.id !== id);
        return proxy.writeQuery({ query, variables, data });
      })(S.gets(S.is($.Object))(['data', 'removeShow'])(response)),
    }),
    update: show => client.mutate({
      mutation: showQueries.update,
      variables: { input: show },
      update: (proxy, response) => S.map(updateShow => {
        const query = showQueries.list;
        const variables = watchVariables;
        const data = proxy.readQuery({ query, variables });
        const itemIndex = data.listSessions.items.findIndex(({ id }) => id === show.id);
        data.listSessions.items[itemIndex] = {
          ...data.listSessions.items[itemIndex],
          ...updateShow,
        };
        return proxy.writeQuery({ query, variables, data });
      })(S.gets(S.is($.Object))(['data', 'updateShow'])(response)),
    }),
    watch(after) {
      watchVariables = { after };
      const query = client.watchQuery({
        query: showQueries.list,
        variables: watchVariables,
        notifyOnNetworkStatusChange: true,
      });
      return S.pipe([
        fromObservable,
        M.map(result => ({
          data: S.fromMaybe([])(S.gets(S.is($.Array($.Object)))(['data', 'listShows', 'items'])(result)),
          loading: result.loading,
          fetchMore: after => query.fetchMore({
            variables: { after },
            updateQuery: (prev, { fetchMoreResult }) => {
              return {
                ...prev,
                listShows: {
                  ...prev.listShows,
                  items: [...prev.listShows.items, ...fetchMoreResult.listShows.items]
                    .sort(sortItems)
                    .filter((item, i, a) => (item.id !== (a[i + 1] || {}).id)),
                },
              };
            },
          }),
        })),
        M.map(({ data, ...rest }) => ({
          data: data
            .map(parseItem)
            .sort(sortItems),
          ...rest,
        })),
        M.recoverWith(error => M.now({
          data: [],
          loading: false,
          fetchMore: () => {},
          error: new Error({
            'Network error: Response not successful: Received status code 403': 'No autorizado',
          }[error.message] || error.message),
        })),
      ])(query);
    },
  };
}

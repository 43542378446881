import React, { useState } from 'react';
import { useStream } from 'useStream';
import { Image, Button, Input, Form, Select } from 'semantic-ui-react';
// Date and time input
// Map and geocoding
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import ReactMapGL, { Marker } from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import MapPin from 'components/MapPin';
import DateInput from 'components/DateInput';
import MarkdownEditor from 'components/MarkdownEditor';

const DialogInput = ({ id, label, data, onChange, Component = Input, parse, ...rest }) => {
  const [value, update] = useState(data[id] || '');
  return (
    <Form.Field>
      <label>{label}</label>
      <Component
        placeholder={`${label}...`}
        value={value}
        onChange={(_, { value }) => {
          update(value);
          onChange(id, parse ? parse(value) : value);
        }}
        {...rest}
      />
    </Form.Field>
  );
};

const Map = ({ value = {}, onChange }) => {
  const [mapRef, setMapRef] = useState();
  const smallMap = window.innerWidth < 768;
  const [mapViewport, setMapViewport] = useState({
    width: smallMap ? 333 : 400,
    height: 400,
    latitude: value.latitude || 52.515,
    longitude: value.longitude || 13.4,
    zoom: 12,
  });
  const [location, setLocation] = useState({
    latitude: mapViewport.latitude,
    longitude: mapViewport.longitude,
  });
  return (
    <ReactMapGL
      ref={ref => !mapRef && ref && setMapRef({ current: ref })}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      onViewportChange={viewport => setMapViewport({ ...mapViewport, ...viewport })}
      mapStyle="mapbox://styles/mapbox/bright-v9"
      {...mapViewport}>
      <Marker longitude={location.longitude} latitude={location.latitude}>
        <MapPin />
      </Marker>
      {mapRef && (
        <Geocoder
          countries="de"
          mapRef={mapRef}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          onViewportChange={viewport => {
            const location = {
              latitude: viewport.latitude,
              longitude: viewport.longitude,
            };
            onChange(null, { value: location });
            setLocation(location);
            setMapViewport({
              ...mapViewport,
              ...viewport,
              transitionDuration: 2000,
            });
          }}
        />
      )}
    </ReactMapGL>
  );
};

const SaveButton = ({ onSave, saveStream, content = 'Guardar' }) => {
  const saveState = useStream(saveStream, { canSave: false });
  return (
    <Button
      disabled={!saveState.canSave}
      onClick={onSave}
      positive
      labelPosition="right"
      icon="checkmark"
      content={content}
    />
  );
};

const storageUrl = `https://${process.env.REACT_APP_MEDIA_URL}/public`;
const ShowForm = ({ show, icons }) => {
  const inputProps = { data: show.data, onChange: show.edit };
  const iconOptions = icons.map(icon => ({
    key: icon,
    value: icon,
    text: <Image src={`${storageUrl}/${icon}`} />,
  }));
  return (
    <Form>
      <DialogInput
        id="icon"
        label="Icono"
        Component={Select}
        options={iconOptions}
        compact
        style={{ width: 'initial' }}
        {...inputProps}
      />
      <DialogInput id="title" label="Título" {...inputProps} />
      <Form.Field>
        <label>Fecha y hora</label>
        <DateInput value={show.data.date} onChange={date => show.edit('date', date)} />
      </Form.Field>
      <DialogInput id="locationName" label="Sitio" {...inputProps} />
      <DialogInput id="location" label="Localización" Component={Map} {...inputProps} />
      <DialogInput id="description" label="Descripción" Component={MarkdownEditor} {...inputProps} />
    </Form>
  );
};

ShowForm.SaveButton = SaveButton;
export default ShowForm;

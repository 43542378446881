import { useEffect, useState } from 'react';
import { messageTypes } from '../services/liveshowApi';

// TODO This shouldn't be responsible of .init() services,
// make a separate service pool / message handler which:
//   - Allow listeners to register for service events
//   - .init() a service has at least 1 listener
//   - .close() a service when there are no more listeners
// The initial reason why this is done here is because no other
// client code was listening to the liveshowApi, therefore it
// worked to couple its messageHandler to the useLiveshowApi hook,
// but if the liveshowApi is supposed to be used also from outside
// the scope of React (which would be good, as it allows for
// React-agnostic controllers) this can't be done anymore.
function getUseLiveshowApi(liveshowApi) {
  const lastMessage = {};
  const listeners = Object.values(messageTypes).reduce((result, messageType) => ({
    ...result,
    [messageType]: new Map(),
  }), {});

  function messageHandler(messageType, messageData) {
    if (messageType) {
      lastMessage[messageType] = messageData;
      listeners[messageType].forEach((listener) => listener(messageData));
    }
  }

  liveshowApi.init(messageHandler);

  return function useLiveshowApi(messageType, initValue = null) {
    const [currentMessage, setMessage] = useState(lastMessage[messageType] || initValue);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
      if (currentMessage !== lastMessage[messageType] && typeof lastMessage[messageType] !== 'undefined') {
        setMessage(lastMessage[messageType]);
      }
      const listener = Symbol('listener');
      listeners[messageType].set(listener, (...args) => {
        setMessage(...args);
        setLoading(false);
      });
      return () => listeners[messageType].delete(listener);
    }, [messageType, initValue]); // eslint-disable-line react-hooks/exhaustive-deps

    function setApiState(messageData) {
      setLoading(true);
      liveshowApi.sendMessage(messageType, messageData);
    };

    return [currentMessage, setApiState, isLoading];
  };
}

export {
  getUseLiveshowApi,
}

import {
  easeInOutQuad,
  interpolate,
  sequence,
} from './sequences';

const Layouts = {
  intro: 'intro',
  general: 'general',
  couple: 'couple', // Batata Flow
  promo: 'promo', // Pensamientos de Caipirinha
  promoUp: 'promoUp',
  promoDown: 'promoDown',
  countdown: 'countdown', // Conga de Jalisco
};

// TODO Add support for opacity (via image filters in OBS)
const layoutConfig = {
  [Layouts.intro]: {
    conductor: {
      position: [640, 364],
      scale: 1.45,
    },
    idle1: {
      scale: 0,
    },
    idle2: {
      scale: 0,
    },
    idle3: {
      scale: 0,
    },
    idle4: {
      scale: 0,
    },
    media: {
      scale: 0,
    },
  },
  [Layouts.general]: {
    conductor: {
      scale: 0.809,
    },
    idle1: {
      position: [397, 228],
      scale: 0.8,
    },
    idle2: {
      position: [891, 228],
      scale: 0.8,
    },
    idle3: {
      position: [397, 489],
      scale: 0.8,
    },
    idle4: {
      position: [891, 489],
      scale: 0.8,
    },
    media: {
      scale: 0,
    },
  },
  [Layouts.couple]: {
    conductor: {
      position: [640, 499],
      scale: 0.603,
    },
    player1: {
      position: [328, 253],
      scale: 1,
    },
    player2: {
      position: [947, 253],
      scale: 1,
    },
    idle1: {
      position: [373, 533],
      scale: 0.712,
    },
    idle2: {
      position: [884, 533],
      scale: 0.712,
    },
    media: {
      scale: 0,
    },
  },
  [Layouts.promo]: {
    conductor: {
      position: [162, 340],
      scale: 0.603,
    },
    media: {
      position: [970, 243],
      scale: 1,
    },
    player1: {
      position: [355, 243],
      scale: 1,
    },
    idle1: {
      position: [209, 522],
      scale: 0.7,
    },
    idle2: {
      position: [643, 522],
      scale: 0.7,
    },
    idle3: {
      position: [1076, 522],
      scale: 0.7,
    },
  },
  [Layouts.promoUp]: {
    conductor: {
      position: [640, 480],
      scale: 0.809,
    },
    idle1: {
      position: [300, 228],
      scale: 0.8,
    },
    idle2: {
      position: [988, 228],
      scale: 0.8,
    },
    idle3: {
      position: [397, 489],
      scale: 0.8,
    },
    idle4: {
      position: [891, 489],
      scale: 0.8,
    },
    media: {
      position: [640, 228],
      scale: 0.8,
      crop: {
        top: 0,
        bottom: 0,
        left: 130,
        right: 130,
      },
    },
  },
  [Layouts.promoDown]: {
    conductor: {
      position: [640, 238],
      scale: 0.809,
    },
    idle1: {
      position: [397, 228],
      scale: 0.8,
    },
    idle2: {
      position: [891, 228],
      scale: 0.8,
    },
    idle3: {
      position: [300, 489],
      scale: 0.8,
    },
    idle4: {
      position: [988, 489],
      scale: 0.8,
    },
    media: {
      position: [640, 489],
      scale: 0.8,
      crop: {
        top: 0,
        bottom: 0,
        left: 130,
        right: 130,
      },
    },
  },
  [Layouts.countdown]: {
    conductor: {
      position: [276, 270],
      scale: 1,
    },
    player1: {
      position: [674, 246],
      scale: 1,
    },
    idle1: {
      position: [209, 522],
      scale: 0.7,
    },
    idle2: {
      position: [643, 522],
      scale: 0.7,
    },
    idle3: {
      position: [1076, 522],
      scale: 0.7,
    },
    media: {
      scale: 0,
    },
  },
};

function getSlot(streamer, init = {}) {
  const listeners = [];
  let isStreamerOpen = false;

  let slotsCount = init.slotsCount;
  let roleId = init.roleId;

  let position = init.position || [0, 0];
  let nextPosition = position;

  let scale = init.scale || 1;
  let nextScale = scale;

  let visible = init.visible || true;

  const extraItems = init.extraItems || [];

  function getId() {
    return `slot_${slotsCount}.${roleId}`;
  }

  function render(onFinish) {
    if (!isStreamerOpen) return;

    const duration = position === nextPosition && scale === nextScale
      ? 0
      : 500;
    const fps = 15;

    const id = getId();
    const renderItems = [{ id }, ...extraItems];
    const renderItemsLength = renderItems.length;

    sequence(id, [0, 1], [duration, 1000 / fps], value => {
      const x = easeInOutQuad(value);

      const currentPosition = [
        interpolate(position[0], nextPosition[0], x),
        interpolate(position[1], nextPosition[1], x),
      ];
      const currentScale = interpolate(scale, nextScale, x);

      for (let i = 0; i < renderItemsLength; ++i) {
        const item = renderItems[i];
        streamer.setItemProperties(item.id, {
          position: {
            x: currentPosition[0],
            y: currentPosition[1],
          },
          scale: currentScale * (item.scale || 1),
          visible,
          ...(cropToAdd ? {
            crop: {
              top: originalCrop.top + cropToAdd.top,
              bottom: originalCrop.bottom + cropToAdd.bottom,
              left: originalCrop.left + cropToAdd.left,
              right: originalCrop.right + cropToAdd.right,
            },
          } : {})
        })
      }
    });
    setTimeout(() => {
      position = nextPosition;
      scale = nextScale;
      if (typeof onFinish === 'function') {
        onFinish();
      }
    }, duration);
  }

  // BOooomm! Here comes the Hack
  let originalCrop = null;
  let cropToAdd = null;
  async function addCrop(input) {
    if (cropToAdd || !isStreamerOpen) return;
    const response = await streamer.getItemProperties(getId());
    if (!response) return;
    originalCrop = response.crop;
    cropToAdd = input;
  }

  function resetCrop() {
    if (cropToAdd) {
      cropToAdd = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      };
      render(() => {
        cropToAdd = null;
      });
    }
  }

  function close() {
    visible = false;
    render();
    listeners.forEach(unlisten => unlisten());
  }

  listeners.push(streamer.isOpen.listen(open => {
    isStreamerOpen = open;
    render();
  }, true));

  return {
    close,
    render,
    get slotsCount() { return slotsCount; },
    set slotsCount(input) { slotsCount = input; },
    get roleId() { return roleId; },
    set roleId(input) { roleId = input; },
    get position() { return position; },
    set position(input) { nextPosition = [...input]; },
    get scale() { return scale; },
    set scale(input) { nextScale = input; },
    get visible() { return scale; },
    set visible(input) {
      if (visible !== input) {
        visible = input;
        render();
      }
    },
    addCrop,
    resetCrop,
  };
}

function getSlots(streamer) {
  const slots = [];

  function clearSlots() {
    slots.forEach(slot => slot.close());
    slots.length = 0;
  }

  // Workaround to debounce calls due to React re-renders.
  // TODO Refactor the whole thing so that the liveshowApi
  // isn't coupled to React (see hooks/liveshowApi),
  // so that an independent controller can manage this service.
  let currentSlotsConfig = null;
  let currentPlayers = null;
  let currentLayout = '';
  function setLayout(slotsConfig, players, layout) {
    if (
      slotsConfig === currentSlotsConfig
      && players === currentPlayers
      && layout === currentLayout
    ) return;
    currentSlotsConfig = slotsConfig;
    currentPlayers = players;
    currentLayout = layout;
    const activeSlots = slotsConfig.filter(({ connected }) => connected);
    if (activeSlots.length !== slots.length) {
      clearSlots();
      activeSlots.forEach(({ role }, i) => (
        slots.push(getSlot(streamer, {
          role,
          slotsCount: activeSlots.length,
          roleId: i + 1,
          visible: true,
          extraItems: i === 0
            ? [ { id: `mask_conductor_${activeSlots.length}`, scale: 1.02 } ]
            : []
        }))
      ));
    }
    activeSlots.forEach(({ role }, i) => {
      const currentRole = typeof role === 'string' ? role : players[role].role;
      const { position, scale, visible, crop } = layoutConfig[layout][currentRole] || {};
      const slot = slots[i];
      slot.position = position !== undefined ? position : [640, 360];
      slot.scale = scale !== undefined ? scale : 1;
      slot.visible = visible !== undefined ? visible : true;
      if (crop) {
        // Hack
        // WARNING CAUTION WHATEVER!!! If the number of participants
        // changes, the crop state is lost and it gets applied multiple
        // times to the source (which is basically a destructive operation).
        // Do NOT do that, fix this shit instead.
        slot.addCrop(crop);
      } else {
        slot.resetCrop();
      }
      slot.render();
    });
  }

  // Hack
  function resetCrop() {
    slots.forEach(slot => slot.resetCrop());
  }

  function close() {
    clearSlots();
  }

  return {
    close,
    setLayout,
    resetCrop, // Hack
  };
}

export {
  Layouts,
  getSlots,
};

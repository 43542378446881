import React, { createContext, useContext } from 'react';

const CangrejoContext = createContext();

export const CangrejoProvider = ({ children, ...contextValue }) => (
  <CangrejoContext.Provider value={contextValue}>
    {children}
  </CangrejoContext.Provider>
);
export const useCangrejoContext = () => useContext(CangrejoContext);

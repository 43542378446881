import React, { useState } from 'react'
import { createMedia } from '@artsy/fresnel';
import {
  Button,
  Container,
  Dropdown,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'
import { useBeiContext } from 'beiContext';

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    desktop: 992,
  }
});

const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

const Logout = () => (
  <>
    Salir
    <Icon style={{ marginLeft: 5 }} name='sign out' />
  </>
);

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
const DesktopContainer = ({
  onLogout,
  MenuEntries,
  children,
}) => {
  const [fixed, setFixed] = useState(false);
  return (
    <div>
      <Visibility
        once={false}
        onBottomPassed={() => setFixed(true)}
        onBottomPassedReverse={() => setFixed(false)}
      >
        <Segment
          inverted
          textAlign='center'
          style={{ padding: '1em 0em' }}
          vertical
        >
          <Menu
            fixed={fixed ? 'top' : null}
            inverted={!fixed}
            pointing={!fixed}
            secondary={!fixed}
            size='large'
          >
            <Container>
              <MenuEntries />
              <Menu.Item position='right'>
                <Button as='a' inverted={!fixed} negative onClick={onLogout}>
                  <Logout />
                </Button>
              </Menu.Item>
            </Container>
          </Menu>
        </Segment>
      </Visibility>
      {children}
    </div>
  );
};

const MobileContainer = ({
  onLogout,
  MenuEntries,
  children,
}) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  return (
    <Sidebar.Pushable>
      <Sidebar
        as={Menu}
        animation='push'
        inverted
        onHide={() => setSidebarOpened(false)}
        vertical
        visible={sidebarOpened}
      >
        <MenuEntries />
      </Sidebar>

      <Sidebar.Pusher dimmed={sidebarOpened}>
        <Segment
          inverted
          textAlign='center'
          style={{ padding: '1em 0em' }}
          vertical
        >
          <Container>
            <Menu inverted pointing secondary size='large'>
              <Menu.Item onClick={() => setSidebarOpened(true)}>
                <Icon name='sidebar' />
              </Menu.Item>
              <Menu.Item position='right'>
                <Button as='a' inverted negative onClick={onLogout}>
                  <Logout />
                </Button>
              </Menu.Item>
            </Menu>
          </Container>
        </Segment>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

const MenuEntry = ({ path, children, currentPath }) => {
  const { navigation: { replace } } = useBeiContext();
  return (
    <Menu.Item onClick={() => replace(path)} active={currentPath === path}>
      {children}
    </Menu.Item>
  );
};

const MenuEntries = () => {
  const { navigation: { replace, getCurrentPath } } = useBeiContext();
  const currentPath = getCurrentPath().split('/')[1];
  const camerinoText = {
    cangrejo: 'Cangrejo',
    'cangrejo-presentador': 'Cangrejo',
  }[currentPath] || 'Camerino';

  return (
    <>
      <MenuEntry path={'shows'}>Shows</MenuEntry>
      <MenuEntry path={'wishlist'}>Corasones</MenuEntry>
      <Dropdown item text={camerinoText}>
        <Dropdown.Menu>
          <Dropdown.Item text='Cangrejo' onClick={() => replace('cangrejo')} active={currentPath === 'cangrejo'} />
          <Dropdown.Item text='Cangrejo (presentador)' onClick={() => replace('cangrejo-presentador')} active={currentPath === 'cangrejo-presentador'} />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const AdminLayoutConnected = ({ children }) => {
  const { auth: { logout } } = useBeiContext();

  const props = {
    onLogout: logout,
    MenuEntries,
  };

  return (
    <>
      <style>{mediaStyles}</style>
      <MediaContextProvider>
        <Media greaterThanOrEqual='desktop'>
          <DesktopContainer {...props}>{children}</DesktopContainer>
        </Media>
        <Media at='mobile'>
          <MobileContainer {...props}>{children}</MobileContainer>
        </Media>
      </MediaContextProvider>
    </>
  );
};

export default AdminLayoutConnected;

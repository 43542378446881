import * as M from '@most/core';
import S from 'utils/S';
import { newDefaultScheduler } from '@most/scheduler';
import router from './router';
import routes from './routes';

let currentPath = '';
export const getCurrentPath = () => currentPath;
 
export function getNavigation({ render, renderError, history, authState }) {
  const navigationStream = M.combine((location, auth) => {
    currentPath = location.pathname;
    const context = {
      location,
      history,
      loggedIn: S.isRight(auth) && (S.fromEither('')(auth) === 'loggedIn'),
    };
    router.resolve(routes, context)
      .then(render)
      .catch(error => {
        console.log('[Navigator]', error.message);
        return renderError[error.status]();
      });
  }, history.location, authState);

  M.runEffects(navigationStream, newDefaultScheduler());
}

import React from 'react';
import { Input } from 'semantic-ui-react';

function NumberInput({ onChange, ...restProps }) {
  return (
    <Input
      {...restProps}
      value={restProps.value}
      onChange={({ target: { value } }) => (
        value.length === 0
          ? onChange(0)
          : value.match(/^\d+$/)
            ? onChange(parseInt(value))
            : restProps.value
      )}
    />
  );
}

export default NumberInput;

import S from 'utils/S';
import * as M from '@most/core';
import { OPERATION } from './constants';

const backendOperation = {
  [OPERATION.CREATE]: 'create',
  [OPERATION.UPDATE]: 'update',
};

export function getRepository(itemBackend, makeItem) {
  // Replace empty or undefined values with null, to mark them as removed
  const mapItemData = itemData => Object.entries(itemData).reduce((result, [key, value]) => ({
    ...result,
    [key]: (value === '' || value === undefined) ? null : value,
  }), {});

  const fetchMoreCalls = new Set();

  return {
    create: ({ id, ...item } = {}) => makeItem(item),
    get: S.compose(makeItem)(itemBackend.get),
    remove: itemBackend.remove,
    save: (operation, item) => S.compose(itemBackend[backendOperation[operation]])(mapItemData)({
      ...S.prop(operation === OPERATION.CREATE ? 'data' : 'updatedData')(item),
    }),
    watch: (watchArg = null) => S.pipe([
      itemBackend.watch,
      M.map(({ fetchMore, ...rest }) => ({
        ...rest,
        fetchMore(after) {
          if (!fetchMoreCalls.has(after)) {
            fetchMoreCalls.add(after);
            return fetchMore(after);
          }
        },
      })),
      M.continueWith(() => {
        fetchMoreCalls.clear();
        return M.empty();
      }),
      M.recoverWith(error => M.now({ error })),
    ])(watchArg),
  };
}

import { getEventEmitter } from '../framework/eventEmitter';

const log = false;

function getStreamer({ client }) {
  let socket = { readyState: 3 };

  const isOpen = getEventEmitter(false);

  let openTime = Date.now();
  let messageId = 0;
  const getMessageId = () => `${openTime}_${(messageId++).toString()}`;

  function init({ host, port, onConnected }) {
    function openConnection() {
      //socket = new client('ws://localhost:2020');
      socket = new client(`ws://${host}:${port}`);
      if (onConnected) {
        socket.addEventListener('open', () => {
          openTime = Date.now();
          onConnected(true);
          isOpen(true);
        });
        socket.addEventListener('error', () => {
          onConnected(false);
          isOpen(false);
        });
        socket.addEventListener('close', () => {
          onConnected(false);
          isOpen(false);
        });
      }
    }
    if (host && port) {
      openConnection();
    } else {
      console.warn('Missing data');
    }
  }

  function sendMessage(data) {
    if (socket.readyState === 1) {
      return socket.send(JSON.stringify(data));
    }
  }

  function responseOf(messageId) {
    return new Promise((resolve, reject) => {
      if (socket.readyState === 1) {
        const messageHandler = (event) => {
          const data = JSON.parse(event.data);
          if (data['message-id'] === messageId) {
            removeListener();
            if (data.status === 'error') {
              reject(new Error(data.error));
            } else {
              resolve(data);
            }
          }
        };
        const removeListener = () => {
          clearTimeout(timeout);
          if (socket.readyState === 1) {
            socket.removeEventListener('message', messageHandler);
          }
        };

        socket.addEventListener('message', messageHandler);

        // Stop listening if we don't get response in a while
        const timeout = setTimeout(() => {
          reject(new Error('Timeout'));
          removeListener();
        }, 5000);
      } else {
        reject(new Error('Not connected'));
      }
    });
  }

  function close() {
    if (socket.readyState < 2) {
      socket.close();
    }
  }

  function insertText(text) {
    function getFontSize(textLength) {
      const breakpoints = [30, 40, 50, 60, 70];
      if (textLength < breakpoints[0]) {
        return 48;
      } else if (textLength >= breakpoints[0] && textLength < breakpoints[1]) {
        return 40;
      } else if (textLength >= breakpoints[1] && textLength < breakpoints[2]) {
        return 32;
      } else if (textLength >= breakpoints[2] && textLength < breakpoints[3]) {
        return 25;
      } else if (textLength >= breakpoints[3] && textLength < breakpoints[4]) {
        return 23;
      } else {
        return 20;
      }
    }

    sendMessage({
      'request-type': 'SetTextFreetype2Properties',
      'message-id': getMessageId(),
      source: 'insertedText',
      text: text.length > 0 ? text : ' ',
      font: {
        size: getFontSize(text.length),
      },
    });
  }

  function setPlayerText(playerId, text) {
    function getFontSize(textLength) {
      const breakpoints = [13, 23, 33, 43, 53];
      if (textLength < breakpoints[0]) {
        return 28;
      } else if (textLength >= breakpoints[0] && textLength < breakpoints[1]) {
        return 24;
      } else if (textLength >= breakpoints[1] && textLength < breakpoints[2]) {
        return 20;
      } else if (textLength >= breakpoints[2] && textLength < breakpoints[3]) {
        return 16;
      } else if (textLength >= breakpoints[3] && textLength < breakpoints[4]) {
        return 12;
      } else {
        return 10;
      }
    }

    sendMessage({
      'request-type': 'SetTextFreetype2Properties',
      'message-id': getMessageId(),
      source: `text_${playerId}`,
      text: text.length > 0 ? text : ' ',
      font: {
        size: getFontSize(text.length),
      },
    });
  }

  function setScene(scene) {
    sendMessage({
      'request-type': 'SetCurrentScene',
      'message-id': getMessageId(),
      'scene-name': scene,
    });
  }

  function setVolume(volume) {
    sendMessage({
      'request-type': 'SetVolume',
      'message-id': getMessageId(),
      source: 'desktopAudio',
      volume,
    });
  }

  function setItemProperties(item, { position, scale, visible, crop }) {
    if (log) console.log('[streamer]', 'setItemProperties', item, { position, scale, visible });
    sendMessage({
      'request-type': 'SetSceneItemProperties',
      'message-id': getMessageId(),
      item,
      ...position !== undefined ? { position } : {},
      ...scale !== undefined ? { scale: { x: scale, y: scale } } : {},
      ...visible !== undefined ? { visible } : {},
      ...crop !== undefined ? { crop } : {},
    });
  }

  function getItemProperties(item) {
    if (log) console.log('[streamer]', 'getItemProperties', item);
    const messageId = getMessageId();
    sendMessage({
      'request-type': 'GetSceneItemProperties',
      'message-id': messageId,
      item,
    });
    return responseOf(messageId);
  }

  async function getScreenshot() {
    const messageId = getMessageId();
    sendMessage({
      'request-type': 'TakeSourceScreenshot',
      'message-id': messageId,
      sourceName: 'cangrejo',
      embedPictureFormat: 'jpeg',
      compressionQuality: 50,
      width: 320
    });
    return (await responseOf(messageId)).img;
  }

  return {
    init,
    close,
    insertText,
    setPlayerText,
    setScene,
    setVolume,
    setItemProperties,
    getItemProperties,
    getScreenshot,
    isOpen,
  };
}

export {
  getStreamer,
};

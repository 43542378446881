import React, {useState} from 'react';
import {Button, Modal, Icon, Dropdown} from 'semantic-ui-react';
import S from 'utils/S';
import {useBeiContext} from 'beiContext';
import {useStream} from 'useStream';
import ShowForm from './ShowForm';

const storageUrl = `https://${process.env.REACT_APP_MEDIA_URL}/public`;
const styles = {
  modal: {
    marginTop: '0px !important',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  modalFullscreen: {
    top: 0,
    marginTop: 0,
    minHeight: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    marginLeft: 14,
  },
  showType: {
    width: '60%',
  },
};

const AddShow = ({style = {}}) => {
  const [open, setOpen] = useState(false);
  const {
    showsController: {
      create,
      current,
      select,
      save,
      saveState,
      constants: {OPERATION},
    },
    showTemplatesController: {watch},
  } = useBeiContext();
  const {data: showTemplates} = useStream(watch(), {data: [], loading: true});
  const currentShow = useStream(current, S.Pair(OPERATION.NONE)(S.Nothing));
  const currentSaveState = useStream(saveState, S.Right(''));
  S.either(() => {})(s => {
    if (s === 'success' && open) setOpen(false);
  })(currentSaveState);
  const show = S.compose(S.maybeToNullable)(S.snd)(currentShow);
  const fullscreen = window.innerWidth < 768;
  return (
    <Modal
      open={open}
      size={fullscreen ? 'fullscreen' : 'large'}
      style={styles[fullscreen ? 'modalFullscreen' : 'modal']}
      trigger={
        <Button
          style={style}
          icon
          positive
          labelPosition="left"
          onClick={() => setOpen(true)}>
          <Icon name="add" />
          Añadir show
        </Button>
      }
      onClose={() => {
        console.log(23);
        select(null);
        setOpen(false);
      }}>
      <Modal.Header
        style={styles.header}
        className={fullscreen ? 'fullscreen' : ''}>
        Añadir show
        <Dropdown
          style={styles.showType}
          placeholder="Tipo de show"
          fluid
          selection
          options={showTemplates.map(({id: value, title: text, icon}) => ({
            value,
            text,
            image: {avatar: true, src: `${storageUrl}/${icon}`},
          }))}
          onChange={(event, {value}) =>
            create(showTemplates.find(({id}) => value === id))
          }
        />
        {fullscreen && (
          <Button
            style={styles.closeButton}
            icon="close"
            onClick={() => {
              select(null);
              setOpen(false);
            }}
          />
        )}
      </Modal.Header>
      {show ? (
        <React.Fragment key={show.data.title}>
          <Modal.Content>
            <Modal.Description>
              <ShowForm
                show={show}
                icons={showTemplates.map(({icon}) => icon)}
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <ShowForm.SaveButton
              onSave={save}
              saveStream={show.saveState}
              content="Añadir"
            />
          </Modal.Actions>
        </React.Fragment>
      ) : null}
    </Modal>
  );
};

export default AddShow;

import React, { createContext, useContext } from 'react';

const BeiContext = createContext();

export const BeiProvider = ({ children, ...services }) => (
  <BeiContext.Provider value={services}>
    {children}
  </BeiContext.Provider>
);
export const useBeiContext = () => useContext(BeiContext);

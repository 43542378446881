import { useState, useEffect } from 'react';
import * as M from "@most/core";
import { newDefaultScheduler } from "@most/scheduler";
import S from 'utils/S';
import getStreamSource from "utils/getStreamSource";

const scheduler = newDefaultScheduler();

export const useStream = (stream, initState) => {
  const [streamState, setStreamState] = useState(initState);

  useEffect(() => {
    if (!stream) return;
    // Prepare unmount signal stream
    const { stream: unmountSignal, push: unmountPush, end: unmountEnd } = getStreamSource();
    // Setup and run input stream
    M.runEffects(S.pipe([
      typeof initState !== 'undefined' ? M.startWith(initState) : S.id(Function),
      M.tap(setStreamState),
      M.until(unmountSignal),
    ])(stream), scheduler);
    // Return cleanup function to finish streams
    return function cleanup() {
      unmountPush();
      unmountEnd();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return streamState;
};
